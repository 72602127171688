import { EMPLOYMENT_TYPE } from "config/constants";
import { push } from "connected-react-router";
import moment from "moment";
import {
  setErrorMess,
  setSuccessMess,
} from "redux/reducers/Status/actionTypes";
import routes from "routers/routes";
import { BasicProfileService } from "services/Contractor/NewMember/BasicProfile";
import { ContactProfileService } from "services/Contractor/NewMember/ContactProfile";
import { CVParseService } from "services/Contractor/NewMember/CVParse";
import { EducationService } from "services/Contractor/NewMember/Education";
import { ExperienceService } from "services/Contractor/NewMember/Experience";
import { SkillService } from "services/Contractor/NewMember/Skill";
//import { UserChatEngineService } from "services/Message/User/UserChatEngine";
import password from "secure-random-password";
import { UserService } from "services/User";
import { setUserReducer } from "redux/reducers/User/actionTypes";
import { setProfileReducer } from "redux/reducers/Contractor/actionTypes";

export class UtilCvParsing {
  static async cvParsing(dispatch, user, profile, data, editProfileByUploadCV) {
    // const updateUserChatEngine = async (dataUpdate) => {
    //   await UserChatEngineService.getUserChatEngine(user?.chatUserId)
    //     .then((res) => {
    //       const customJson = {
    //         ...JSON.parse(res?.custom_json),
    //         ...dataUpdate,
    //       };
    //       UserChatEngineService.patchUserChatEngine(user?.chatUserId, {
    //         username: user?.id,
    //         first_name: res?.first_name,
    //         last_name: res?.last_name,
    //         secret: user?.chatSecret,
    //         custom_json: JSON.stringify(customJson),
    //       }).catch((e) => dispatch(setErrorMess(e)));
    //     })
    //     .catch((e) => dispatch(setErrorMess(e)));
    // };

    let dataOfUserChatEngine = {};

    if (data?.cvUploadFileBase64) {
      // console.log("starting cv parse")
      await CVParseService.postCV({
        DocumentAsBase64String: data?.cvUploadFileBase64,
        SkillsSettings: {
          Normalize: true,
          TaxonomyVersion: "",
        },
        IndexingOptions: { IndexId: user?.id, DocumentId: user?.id },
        DocumentLastModified: moment(new Date()).format("YYYY-MM-DD"),
      })
        .then(async (res) => {
          const apiArray = [
            await CVParseService.postUserInformation(
              {
                ResumeData: res?.Value?.ResumeData,
              },
              user?.id,
              user?.id
            ),
          ];

          // Parse basic profile
          const firstName =
            res?.Value?.ResumeData?.ContactInformation?.CandidateName
              ?.GivenName;
          const lastName =
            res?.Value?.ResumeData?.ContactInformation?.CandidateName
              ?.FamilyName;
          const title = !!res?.Value?.ResumeData?.Education?.EducationDetails
            ?.length
            ? !!res?.Value?.ResumeData?.Education?.EducationDetails[0]?.Majors
                ?.length
              ? res?.Value?.ResumeData?.Education?.EducationDetails[0]
                  ?.Majors[0]
              : ""
            : "";
          // console.log("here", firstName, lastName, title)
          if (firstName) {
            const basicProfile = {
              firstName: firstName || "first",
              lastName: lastName || "last",
              title: title || "title",
              about: res?.Value?.ResumeData?.Objective || "",
            };
            dataOfUserChatEngine = {
              ...dataOfUserChatEngine,
              title: basicProfile?.title,
              about: basicProfile?.about,
            };
            if (profile?.basicProfile?.id) {
              apiArray.push(
                await BasicProfileService.putBasicProfile(
                  basicProfile,
                  profile?.basicProfile?.id
                )
                  .then((res) => {
                    dispatch(
                      setProfileReducer({ ...profile, basicProfile: res })
                    );
                  })
                  .catch((e) => dispatch(setErrorMess(e)))
              );
            } else {
              apiArray.push(
                await BasicProfileService.postBasicProfile(basicProfile)
                  .then((res) => {
                    dispatch(
                      setProfileReducer({ ...profile, basicProfile: res })
                    );
                  })
                  .catch((e) => dispatch(setErrorMess(e)))
              );
            }
          }

          // country code is not actively used at the moment
          // Parse contact profile
          // const countryCode =
          //   res?.Value?.ResumeData?.ContactInformation?.Location?.CountryCode;

          const mobile = !!res?.Value?.ResumeData?.ContactInformation
            ?.Telephones?.length
            ? res?.Value?.ResumeData?.ContactInformation?.Telephones[0]
                ?.Normalized
            : "";
          const contactProfile = {
            countryCode: "+64", // hardcoding the country code th nz
            mobile: mobile, // removed the idea of having this mandatory was || "0000000"
            city:
              res?.Value?.ResumeData?.ContactInformation?.Location
                ?.Municipality || "",
          };
          dataOfUserChatEngine = {
            ...dataOfUserChatEngine,
            city: contactProfile?.city,
          };
          if (profile?.contact?.id) {
            apiArray.push(
              await ContactProfileService.putContactProfile(
                contactProfile,
                profile?.contact?.id
              )
                .then((res) => {
                  dispatch(setProfileReducer({ ...profile, contact: res }));
                })
                .catch((e) => dispatch(setErrorMess(e)))
            );
          } else {
            apiArray.push(
              await ContactProfileService.postContactProfile(contactProfile)
                .then((res) => {
                  dispatch(setProfileReducer({ ...profile, contact: res }));
                })
                .catch((e) => dispatch(setErrorMess(e)))
            );
          }

          // Parse education
          const educationList =
            res?.Value?.ResumeData?.Education?.EducationDetails?.map((item) => {
              if (item.SchoolName?.Normalized && item?.Majors) {
                let endDate = item?.LastEducationDate?.Date;
                if (endDate) {
                  endDate = new Date(endDate);
                } else {
                  endDate = new Date();
                }
                return {
                  schoolName: item?.SchoolName?.Normalized,
                  degreeName: item?.Majors[0] || "",
                  endDate: endDate,
                  endDateIsPresent:
                    item?.LastEducationDate?.IsCurrentDate || true,
                };
              }
              return null;
            }).filter((item) => item?.schoolName && item?.degreeName);
          if (educationList?.length > 0) {
            if (profile?.education.length > 0) {
              const listIds = [];
              profile?.education.forEach((item) => listIds.push(item?.id));
              await EducationService.deleteEducation({ ids: listIds }).then(
                async () => {
                  apiArray.push(
                    await EducationService.putEducation(educationList)
                      .then((res) => {
                        dispatch(
                          setProfileReducer({ ...profile, education: res })
                        );
                      })
                      .catch((e) => dispatch(setErrorMess(e)))
                  );
                }
              );
            } else {
              apiArray.push(
                await EducationService.postEducation(educationList)
                  .then((res) => {
                    dispatch(setProfileReducer({ ...profile, education: res }));
                  })
                  .catch((e) => dispatch(setErrorMess(e)))
              );
            }
          }

          // Parse experience
          const experienceList =
            res?.Value?.ResumeData?.EmploymentHistory?.Positions?.map(
              (item) => {
                return {
                  title: item?.JobTitle?.Normalized || item?.JobTitle?.Raw,
                  company:
                    item?.Employer?.Name?.Normalized ||
                    item?.Employer?.Name?.Raw,
                  startDate: item?.StartDate?.Date
                    ? new Date(item?.StartDate?.Date)
                    : "",
                  endDate: item?.EndDate?.IsCurrentDate
                    ? null
                    : item?.EndDate?.Date
                    ? new Date(item?.EndDate?.Date)
                    : "",
                  endDateIsPresent: item?.IsCurrent,
                  summary: item?.Description,
                  city: item?.Employer?.Location?.Municipality,
                  country: item?.Employer?.Location?.CountryCode,
                  employmentType: EMPLOYMENT_TYPE.CONTRACT,
                };
              }
            ).filter(
              (item) =>
                item.title &&
                item.company &&
                item.startDate &&
                item.employmentType
            );

          if (experienceList?.length > 0) {
            if (profile?.experience?.length > 0) {
              const listIds = [];
              profile?.experience.forEach((item) => listIds.push(item?.id));
              await ExperienceService.deleteExperience({ ids: listIds }).then(
                async () => {
                  apiArray.push(
                    await ExperienceService.putExperience(experienceList)
                      .then((res) => {
                        dispatch(
                          setProfileReducer({ ...profile, experience: res })
                        );
                      })
                      .catch((e) => dispatch(setErrorMess(e)))
                  );
                }
              );
            } else {
              apiArray.push(
                await ExperienceService.postExperience(experienceList)
                  .then((res) => {
                    dispatch(
                      setProfileReducer({ ...profile, experience: res })
                    );
                  })
                  .catch((e) => dispatch(setErrorMess(e)))
              );
            }
          }

          // Parse skill
          const skillList = res?.Value?.ResumeData?.Skills?.Raw?.map(
            (item) => item?.Name
          );
          const skillListLength = skillList?.length || 0;
          if (skillListLength >= 3) {
            if (skillListLength > 10) {
              skillList.splice(10, skillListLength - 10);
            }
            if (profile?.skill?.id) {
              apiArray.push(
                await SkillService.putSkill(skillList, profile?.skill?.id)
                  .then((res) => {
                    dispatch(setProfileReducer({ ...profile, skill: res }));
                  })
                  .catch((e) => dispatch(setErrorMess(e)))
              );
            } else {
              apiArray.push(
                await SkillService.postSkill(skillList)
                  .then((res) => {
                    dispatch(setProfileReducer({ ...profile, skill: res }));
                  })
                  .catch((e) => dispatch(setErrorMess(e)))
              );
            }
          }

          await Promise.all(apiArray)
            .then(() => {

              
                          dispatch(
                            setUserReducer({
                              ...user,
                              // chatUserId: res?.id,
                              // chatSecret: chatSecret,
                            })
                          );
              
              // if (user?.chatUserId) {
               // updateUserChatEngine(dataOfUserChatEngine);
              // } else {
              //   const chatSecret = password.randomPassword({
              //     characters: [password.lower, password.upper, password.digits],
              //   });
              //   // UserChatEngineService.postUserChatEngine({
              //   //   username: user?.id,
              //   //   first_name: firstName,
              //   //   last_name: lastName,
              //   //   secret: chatSecret,
              //   //   custom_json: JSON.stringify(dataOfUserChatEngine),
              //   // })
              //     .then((res) => {
              //       UserService.putUser(user?.id, {
              //         ...user,
              //         //chatUserId: res?.id,
              //         //chatSecret: chatSecret,
              //       })
              //         .then(() => {
              //           dispatch(
              //             setUserReducer({
              //               ...user,
              //               chatUserId: res?.id,
              //               chatSecret: chatSecret,
              //             })
              //           );
              //         })
              //         .catch((e) => dispatch(setErrorMess(e)));
              //     })
              //     .catch((e) => dispatch(setErrorMess(e)));
              // }
              if (editProfileByUploadCV === false) {
                console.log("and here", firstName, lastName, title)
                if (firstName && firstName.length > 1 && lastName && lastName.length > 1 && title && title.length > 1) {
                  dispatch(push(routes.contractor.newMember.preference));
              } else {
                  dispatch(push(routes.contractor.newMember.basicProfile));
              }
              } else {
                dispatch(setSuccessMess("Successfully update"));
              }
            })
            .catch((e) => dispatch(setErrorMess(e)));
        })
        .catch((e) => dispatch(setErrorMess(e)));
    }
  }
}