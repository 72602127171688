import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Table,
  FormGroup,
  Label,
  Input,
  Form, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import * as yup from 'yup';
import classes from "./styles.module.scss";
import CustomSlider from 'components/Common/CustomSlider';
import CustomInput from 'components/Common/CustomInput';
import CustomCheckboxFlip from 'components/Common/CustomCheckBoxFlip';
import CustomCheckbox from 'components/Common/CustomCheckbox';
import CustomTextarea from 'components/CustomTextarea';
import CustomDatePicker from 'components/Common/CustomDatePicker';
import GanttChart from 'components/Common/charts/ganttChart';
import { ProjectService } from 'services/Employer/Listing/Project';
import { JobService } from 'services/Employer/Listing/Job';
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SearchLocationInput from "components/Common/GoogleAddress";
import { ReducerType } from "redux/reducers";
import CustomSelect from 'components/Common/CustomSelect';
import { PreferencePolicyTypes } from 'models/Contractor/NewMember/Preference';
import {
  getJobList,
} from "redux/reducers/Employer/actionTypes";
import { setSettingReducer, getSetting } from "redux/reducers/Employer/actionTypes";


export default function Client({ onClose }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const { setting } = useSelector((state: ReducerType) => state.employer);
  const [clients, setClients] = useState([]);
  const [rawClientData, setRawClientData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null); // State to manage selected client for editing
  const [isPayrollIncluded, setIsPayrollIncluded] = useState(false);
  const [isPercentage, setIsPercentage] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isAddingClient, setIsAddingClient] = useState(false);
  const [isEditingClient, setIsEditingClient] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalData, setModalData] = useState(null);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [loading, setLoading] = useState(false); // Loading state
  // This assumes `selectedClient` is always defined. Adjust logic as needed.
  const isDisabled = Boolean(selectedClient?.signedContract);

  // Automatically set stage to 'client' if `signedContract` is true
  if (isDisabled && selectedClient.stage !== 'client') {
    setSelectedClient({ ...selectedClient, stage: 'client' });
  }

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, 'At least 2 characters')
      .max(30, 'Must be 30 characters or less')
      .required('First name is required'),
    lastName: yup
      .string()
      .min(2, 'At least 2 characters')
      .max(30, 'Must be 30 characters or less')
      .required('Last name is required'),
    company: yup
      .string()
      .min(2, 'At least 2 characters')
      .max(50, 'Must be 50 characters or less')
      .required('Company name is required'),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, 'Phone number must be numeric')
      .required('Phone number is required'),
    email: yup
      .string()
      .email('Invalid email format')
      .required('Email is required'),
  });

  const {
    register,
    watch,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      id: '',
      firstName: '',
      lastName: '',
      company: '',
      phone: '',
      email: '',
    }
  });

  const [firstName, lastName, company, phone, email] = watch(['firstName', 'lastName', 'company', 'phone', 'email']);

  // Determine if the Save button should be enabled
  const isSaveDisabled = !(
    firstName &&
    lastName &&
    company &&
    phone &&
    email
  );

    // Modal confirmation: trigger proposal when "Yes" is clicked
    const confirmProposal = () => {
      if (modalData) {
        triggerClient(modalData.id, modalData.email); // Trigger the client proposal
      }
    };

  const handleCheckboxChange = (isChecked) => {
    setIsPercentage(isChecked);
  };

  // const onSubmit = (data) => {
    
  //   if (selectedClient?.stage === 'leadProposal') {
  //     // Show modal confirmation before triggering the proposal
  //     setModalData({ ...data });
  //     toggleModal(); // Open the modal
  //   } else {
  //     // Proceed with normal save if stage is not 'leadProposal'
  //     handleSave(data);
  //   }
  // };

  const handleEditClick = (client) => {
    // Adjust client object as necessary before setting it
    const adjustedClient = {
      ...client,
      stage: client.signedContract ? 'client' : client.stage || 'lead', // Default to 'lead' if no stage is set
    };
    setSelectedClient(adjustedClient);
    setIsAddingClient(false); // We are editing, not adding
    setIsEditingClient(true); // Show the edit form
  };

  const handleAddClick = () => {
    setSelectedClient({
      firstName: '',
      lastName: '',
      company: '',
      phone: '',
      email: '',
      stage: 'lead',
    });
    setIsAddingClient(true);
    setIsEditingClient(false);
  };

  const handleSave = (data) => {
    // If the stage is 'leadProposal', open the modal and stop further execution
    if (selectedClient?.stage === 'leadProposal') {
      setModalData({ ...data });
      toggleModal(); // Open the modal
    }
  
    // Proceed with saving if it's not 'leadProposal'
    if (isAddingClient) {
      addClient(data);
    } else {
      editClient({ ...selectedClient, ...data });
    }
  
    // Only set these to false after saving is complete
    setIsAddingClient(false);
    // setIsEditingClient(false);
  };

  const editClient = (clientDetails) => {
    // Make sure clientDetails has an 'id' field representing the client's ID
    if (!clientDetails || !clientDetails.id) {
      console.error("clientDetails is missing 'id'. Cannot update client.");
      return;
    }

    const clientId = clientDetails.id;
    const clientDataToUpdate = { ...clientDetails };
    delete clientDataToUpdate.id; // Assuming the ID should not be part of the update payload
    let chargeAmount;

    // If percentage is checked, calculate percentage-based chargeAmount
    if (isPercentage) {
      chargeAmount = sliderValue;  // Assuming sliderValue is your percentage
    } else {
      // Otherwise use fixedPriceOffer as the chargeAmount
      chargeAmount = clientDataToUpdate.fixedPriceOffer || 0;
    }
  
    // Set chargeAmount to the correct field before sending to backend
    const payload = {
      ...clientDataToUpdate,
      chargeAmount: chargeAmount, // Ensure this value is included in the payload
    };
    // console.log(payload)
    JobService.putClient(payload, clientId)
      .then(response => {
        setLastUpdate(Date.now()); // Trigger refresh
      })
      .catch(error => console.error('Error updating client:', error));
  };


  const triggerClient = (clientId, email) => {
    setLoading(true);
    JobService.postTriggerProposal({ clientId, email })
      .then((response) => {
        setLoading(false);
        setLastUpdate(Date.now());
        setModal(false); // Close the modal after success
        alert('Proposal sent successfully!');
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage('Failed to send proposal. Please try again.');
        console.error('Error triggering client:', error);
      });
  };

  const toggleModal = () => setModal(!modal);


  const addClient = (clientDetails) => {
    fetch('https://j9z0sb36kk.execute-api.ap-southeast-2.amazonaws.com/prod/assistcreateprofile/user', {
      method: 'POST',
      body: JSON.stringify({
        email: clientDetails.email, 
        username: clientDetails.email
      }),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    
    .then(response => {
      // console.log(response)
      if (!response.ok) {
        if (response.status === 500) { // This status code might need to be adjusted
          setShowError(true);
          setErrorMessage("Client already has a Necta Account, please contact Necta administrator");
          throw new Error("User already exists");
        }
        return Promise.reject(response);
      }
      return response.json();
    })
    .then(userResponse => {
      // console.log(userResponse)
      const clientDataWithUsername = userResponse.userid
      ? { ...clientDetails, username: userResponse.userid }
      : { ...clientDetails };
      // Ensure stage is included
      const clientDataWithStage = { ...clientDataWithUsername, stage: clientDetails.stage || 'lead' };
      // console.log(clientDataWithStage)
      let chargeAmount;

      // If percentage is checked, calculate percentage-based chargeAmount
      if (isPercentage) {
        chargeAmount = sliderValue;  // Assuming sliderValue is your percentage
      } else {
        // Otherwise use fixedPriceOffer as the chargeAmount
        chargeAmount = clientDataWithStage.fixedPriceOffer || 0;
      }
    
      // Set chargeAmount to the correct field before sending to backend
      const payload = {
        ...clientDataWithStage,
        chargeAmount: chargeAmount, // Ensure this value is included in the payload
      };
      // console.log(payload)
      triggerGitHubAction()
      return JobService.postClient(payload);
    })
    .then(response => {
      setLastUpdate(Date.now());
    })
    .catch(error => {
      console.error('Error:', error);
      if (!showError) {
        setShowError(true);
        setErrorMessage("Client already exists or there is an error in creating, please contact Necta Administrators");
      }
    });
  };

  const handlePayrollCheckboxChange = (isChecked) => {
    setIsPayrollIncluded(isChecked);
  };

  const triggerGitHubAction = async () => {
    const githubApiUrl = 'https://api.github.com/repos/Surge-NZ/necta/dispatches';
    const token = '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';

    const payload = {
      event_type: 'listings',
    };

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/vnd.github.v3+json',
      'Content-Type': 'application/json',
    };

    try {
      const response = await fetch(githubApiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('GitHub Actions trigger failed');
      }
    } catch (error) {
      console.error('Error triggering GitHub Actions:', error);
    }
  };

  useEffect(() => {
    if (selectedClient) {
      setValue('id', selectedClient.id);
      setValue('firstName', selectedClient.firstName);
      setValue('lastName', selectedClient.lastName);
      setValue('company', selectedClient.company);
      setValue('phone', selectedClient.phone);
      setValue('email', selectedClient.email);
      setIsPercentage(!!selectedClient.fixedPrice);
    }
  }, [selectedClient, setValue]);

  // useEffect(() => {
  //   console.log('isPercentage:', isPercentage);
  // }, [isPercentage]);

  useEffect(() => {
    JobService.getClient()
      .then(data => {
        const sortedData = data.sort((a, b) => b.id - a.id);
        setRawClientData(sortedData);
        const mappedClients = sortedData.map(client => ({
          value: client.id.toString(),
          label: `${client.firstName} ${client.lastName} | ${client.company}`
        }));
        setClients(mappedClients);
      }).catch(error => console.error('Error fetching clients:', error));
  }, [dispatch, lastUpdate]);

  const handleCloseForm = () => {
    setIsAddingClient(false);
    setIsEditingClient(false);
  };

  return (
    <div className={classes.internalContent}>
      {(!isAddingClient && !isEditingClient) && (
        <>
         <div className={classes.body}>
          <div className={classes.header}>
            <p className={classes.title}>My Clients</p>
            <div className="d-flex justify-content-between">
            <Button color="danger" onClick={onClose}>Close</Button>
            <Button color="success" onClick={handleAddClick}>New Client</Button>
            
            </div>
          </div>
          <Table striped hover className={classes.clientTable}>
            <thead className={classes.tableHeader}>
                <tr>
                <th>ID</th>
                <th>Company</th>
                <th>Name</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {rawClientData.map(client => (
                <tr key={client.id} className={classes.tableRow}>
                    <td>{client.id}</td>
                    <td>{client.company}</td>
                    <td>{client.firstName} {client.lastName}</td>
                    <td>
                    <Button 
                        color="primary" 
                        size="sm" 
                        className="mr-2" 
                        onClick={() => handleEditClick(client)}
                    >
                        Edit
                    </Button>
                    </td>
                </tr>
                ))}
            </tbody>
            </Table>
          </div>
        </>
      )}
      {(isAddingClient || isEditingClient) && (
        <div className={classes.body}>
          <p className={classes.title}>{isAddingClient ? 'Add Client' : 'Edit Client'}</p>
          <Button color="danger" onClick={handleCloseForm}>Back to Clients</Button>
          <Form onSubmit={handleSubmit(handleSave)}>
            <CustomInput
              placeholder='Client First Name'
              value={selectedClient?.firstName || ''}
              autoComplete='off'
              refInput={register('firstName')}
              onChange={(e) => setSelectedClient({ ...selectedClient, firstName: e.target.value })}
              errorMessage={errors.firstName?.message}
            />
            <CustomInput
              placeholder='Client Surname Name'
              autoComplete='off'
              value={selectedClient?.lastName || ''}
              onChange={(e) => setSelectedClient({ ...selectedClient, lastName: e.target.value })}
              errorMessage={errors.lastName?.message}
            />
            <CustomInput
              placeholder='Company Name'
              autoComplete='off'
              value={selectedClient?.company || ''}
              onChange={(e) => setSelectedClient({ ...selectedClient, company: e.target.value })}
              errorMessage={errors.company?.message}
            />
            <CustomInput
              placeholder='Phone Number'
              autoComplete='off'
              value={selectedClient?.phone || ''}
              onChange={(e) => setSelectedClient({ ...selectedClient, phone: e.target.value })}
              errorMessage={errors.phone?.message}
            />
            <CustomInput
              placeholder='Client Email Address'
              name='email'
              type='email'
              autoComplete='off'
              value={selectedClient?.email || ''}
              onChange={(e) => setSelectedClient({ ...selectedClient, email: e.target.value })}
              errorMessage={errors.email?.message}
              isDisabled={!isAddingClient}
            />
            <p>To Save the above fields must be filled out in full.</p>
            <h6 className={classes.subtitle}>Client Payment Details</h6>
            <CustomCheckboxFlip
              firstLabel='SAAS'
              secondLabel='ASSIST'
              onChange={handleCheckboxChange}
              checked={isPercentage}
              disabled={selectedClient?.signedContract}
            />
            <p>SAAS is priced per month, Assist is priced on offering per deal. At the moment we can only complete one deal per client, for multi, please see finance.</p>
            <CustomSlider
              name={isPercentage ? 'percentageOffered' : 'fixedPriceOffer'}
              min={isPercentage ? 2.0 : 99}
              max={isPercentage ? 12.0 : 1999}
              step={isPercentage ? 0.01 : 1}
              defaultValue={selectedClient?.chargeAmount ?? (sliderValue > 0 ? sliderValue : (isPercentage ? 6.0 : 6000))}
              control={control}
              valueLabelDisplay='auto'
              showPercentage={isPercentage}
              disabled={selectedClient?.signedContract}
              isPercentage={isPercentage}
            />
            <CustomCheckbox
              content='Payroll Only (Creates no Charge for contractor placement)'
              name='isPayrollIncluded'
              checked={isPayrollIncluded}
              disabled={selectedClient?.signedContract}
              onChange={(e) => handlePayrollCheckboxChange(e.target.checked)}
            />
            <FormGroup>
              <Label for="stage">Client Stage</Label>
              <Input
                id="stage"
                name="stage"
                type="select"
                value={selectedClient?.stage || 'lead'}
                onChange={(e) => setSelectedClient({ ...selectedClient, stage: e.target.value })}
                disabled={selectedClient?.signedContract}
              >
                {selectedClient?.signedContract ? (
                  <option value="client">Client</option>
                ) : (
                  <>
                    <option value="prospectInitial">Prospect - Initial</option>
                    <option value="prospectContact">Prospect - Contact Made</option>
                    <option value="leadDemo">Lead - Demo Booked</option>
                    <option value="leadProposal">Lead - Proposal</option>
                    <option value="leadTrial">Lead - Free Trial</option>
                    <option value="client">Client</option>
                  </>
                )}
              </Input>
              {selectedClient?.signedContract ? (
                <p className="text-muted">
                  The stage is set to Client automatically because a contract is signed.
                </p>
              ) : (
                <p className="text-muted">
                  
                </p>
              )}
            </FormGroup>
            <Button
            color="primary"
            type="submit"
            disabled={isSaveDisabled} // Disable button if required fields are not filled
          >
            Save
          </Button>
          </Form>
                    {/* Modal for confirmation */}
                    <Modal centered isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Send Proposal</ModalHeader>
            <ModalBody>
              Are you sure you want to send a proposal to the client?
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={toggleModal}>No</Button>
              <Button color="success" onClick={confirmProposal} disabled={loading}>
                {loading ? 'Sending...' : 'Yes'}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
      {showError && (
        <div className={classes.errorModal}>
          <h4>Error</h4>
          <p>{errorMessage}</p>
          <Button color="secondary" onClick={() => setShowError(false)}>Close</Button>
        </div>
      )}
    </div>
  );
}
