import { memo, useEffect, useState, useMemo } from 'react';
import {
  Button,
  Container,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Collapse,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import FooterTransparentEmployer from 'components/Footers/FooterTransparentEmployer';
import classes from './styles.module.scss';
import clsx from 'clsx';
import CustomInput from 'components/Common/CustomInput';
import AddEditTalent from './AddEditTalent';
import { setErrorMess, setLoading } from 'redux/reducers/Status/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { TalentProfileService } from 'services/Employer/Listing/Talent/TalentProfile';
import { TalentPreferenceService } from 'services/Employer/Listing/Talent/TalentPreference';
import {
  S3_UPLOAD_TYPE_PRIFIX,
  USER_STATUS,
  WORK_POLICY,
} from 'config/constants';
import { UtilContractor } from 'utils/contractor';
import { convertVisaType } from 'utils/moj';
import { ReducerType } from 'redux/reducers';
import { setTalentReducer, getAllCandidateList } from 'redux/reducers/Employer/actionTypes';
import moment from 'moment';
import RateFilter from './RateFilter';
import CustomDatePicker from 'components/Common/CustomDatePicker';
import CustomDropdown from 'components/Common/CustomDropdown/CustomDropdown';
import { saveAs } from 'file-saver';
import AssignToOpportunity from './AssignToOpportunity';
import { push } from 'connected-react-router';
import { useModal } from 'components/Modals';
import { JobOfferService } from 'services/Contractor/Offer/Offer';
import { JobService } from 'services/Employer/Listing/Job';
import { useWebSocket } from 'services/Message/User/WebSocketProvider.js';
import routes from 'routers/routes';
import { useHistory } from 'react-router-dom';
import { CVParseService } from 'services/Contractor/NewMember/CVParse';
import { AssistCreate } from 'services/Contractor/NewMember/AssistCreate';

interface Props {}

// eslint-disable-next-line no-empty-pattern
const Candidates = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { allCandidatesList, jobList } = useSelector((state: ReducerType) => state.employer);
  const [talentsListRender, setTalentsListRender] = useState([]);
  const [isOpenToggleArr, setIsOpenToggleArr] = useState([]);
  const [policyFilter, setPolicyFilter] = useState(null);
  const [minRateFilter, setMinRateFilter] = useState(null);
  const [maxRateFilter, setMaxRateFilter] = useState(null);
  const [availabilityFilter, setAvailabilityFilter] = useState(null);
  const [cityFilter, setCityFilter] = useState(null);
  const [countryFilter, setCountryFilter] = useState(null);
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [isOpenFilterRateModal, setIsOpenFilterRateModal] = useState({
    isOpen: false,
    type: null,
  });

  const allCountries = useMemo(() => {
    if (talentsListRender) {
      const countriesSet = new Set(talentsListRender.map((item) => item.country));
      return Array.from(countriesSet);
    } else {
      return [];
    }
  }, [talentsListRender]);

  const citiesInSelectedCountry = useMemo(() => {
    if (talentsListRender && countryFilter) {
      const citiesSet = new Set(
        talentsListRender.filter((item) => item.country === countryFilter).map((item) => item.city)
      );
      return Array.from(citiesSet);
    } else {
      return [];
    }
  }, [talentsListRender, countryFilter]);

  const handleProgressOffer = async (jobId, contractorId, employerId) => {
    dispatch(setLoading(true));

    try {
      const creationResponse = await JobService.postJobOffer({
        job: jobId,
        contractor: contractorId,
        employer: employerId,
      });

      const connectOfferId = creationResponse.id;

      await JobOfferService.updateOfferStatus(connectOfferId, {
        job: jobId,
        contractor: contractorId,
        employer: employerId,
      });
    } catch (err) {
      console.log('Error in updating or accepting the offer:', err);
      dispatch(setErrorMess(err));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleRedirectToMessage = (webSocket, myId, receiverId) => {
    if (webSocket && webSocket.webSocket) {
      webSocket.webSocket.send(
        JSON.stringify({
          action: 'sendPrivate',
          data: {
            userId: myId,
            receiverId: receiverId,
          },
        })
      );
    }
  };



  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const toggleAssignModal = () => {
    setIsAssignModalOpen(!isAssignModalOpen);
  };

  const handleClearFilters = () => {
    setPolicyFilter(null);
    setCityFilter(null);
    setCountryFilter(null);
    setMinRateFilter(null);
    setMaxRateFilter(null);
    setAvailabilityFilter(null);
    setStatusFilter(null);
  };

  useEffect(() => {
    dispatch(getAllCandidateList());
  }, [dispatch]);

  useEffect(() => {
    const filteredCandidates = allCandidatesList.filter(
      candidate => candidate?.accepted === true && candidate.source !== 'headhunter'
    );

    const uniqueCandidatesMap = new Map();

    filteredCandidates.forEach(candidate => {
      if (!uniqueCandidatesMap.has(candidate.id) || uniqueCandidatesMap.get(candidate.id).jobId < candidate.jobId) {
        uniqueCandidatesMap.set(candidate.id, candidate);
      }
    });

    const uniqueCandidates = Array.from(uniqueCandidatesMap.values());

    setTalentsListRender(uniqueCandidates);
    const isOpenToggleArr = new Array(uniqueCandidates.length).fill(false);
    setIsOpenToggleArr(isOpenToggleArr);
  }, [allCandidatesList]);

  useEffect(() => {
    let filterList = talentsListRender.filter(
      candidate => candidate?.accepted === true && candidate.source !== 'headhunter'
    );

    if (policyFilter) {
      filterList = filterList.filter(item => item?.workPolicy === policyFilter);
    }

    if (statusFilter) {
      filterList = filterList.filter(item => item?.status === statusFilter);
    }

    if (minRateFilter) {
      filterList = filterList.filter(item => item?.maxSalary >= minRateFilter);
    }

    if (maxRateFilter) {
      filterList = filterList.filter(item => item?.maxRate <= maxRateFilter);
    }

    if (availabilityFilter) {
      filterList = filterList.filter(item => {
        if (item?.availableDate) {
          return moment(availabilityFilter).diff(moment(item?.availableDate), 'seconds') >= 0;
        } else if (item?.noticePeriod) {
          return moment(availabilityFilter).diff(moment().add(2, 'weeks'), 'seconds') >= 0;
        }
      });
    }

    if (countryFilter) {
      filterList = filterList.filter(item => item.country === countryFilter);
    }

    if (cityFilter) {
      filterList = filterList.filter(item => item.city === cityFilter);
    }

    setTalentsListRender(filterList);
  }, [
    policyFilter,
    minRateFilter,
    maxRateFilter,
    statusFilter,
    availabilityFilter,
    cityFilter,
    countryFilter,
  ]);

  const handleToggleSubTable = (index) => {
    const newIsOpen = [...isOpenToggleArr];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpenToggleArr(newIsOpen);
  };

  const [valueOfSearchInput, setValueOfSearchInput] = useState('');

  const [addEditModalOpen, setAddEditModalOpen] = useState({
    isOpenModal: false,
    itemEditModal: false,
    editedItem: null,
  });

  const handleOpenAddTalentForm = () => {
    setAddEditModalOpen({
      itemEditModal: false,
      isOpenModal: true,
      editedItem: null,
    });
  };

  const handleOpenEditTalentForm = (item) => {
    setAddEditModalOpen({
      itemEditModal: true,
      isOpenModal: true,
      editedItem: item,
    });
  };

  const onCloseAddEditModal = () => {
    setAddEditModalOpen({
      isOpenModal: false,
      itemEditModal: false,
      editedItem: null,
    });
  };

  const handleSearch = () => {
    dispatch(setLoading(true));
    TalentProfileService.getFilterNameAllTalents(valueOfSearchInput)
      .then((res) => {
        // Filter talentsListRender based on the search results
        const filteredCandidates = talentsListRender.filter(candidate => 
          res.some(searchResult => 
            searchResult.id === candidate.id
          )
        );
  
        // Update the state with the filtered candidates
        setTalentsListRender(filteredCandidates);
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
  };
  

  const handleRotateIcon = (e) => {
    e.target.classList.toggle(classes.down);
  };

  const uploadCv = (profileId, cv, isPost = false) => {
    const cvKey = `${profileId}-${S3_UPLOAD_TYPE_PRIFIX.TALENT_CV}`;

    cv.arrayBuffer()
      .then((arrayBuffer) => {
        const blob = new Blob([new Uint8Array(arrayBuffer)], {
          type: cv?.type,
        });
        TalentProfileService.postCv(blob, cvKey)
          .then(async (key) => {
            if (isPost) {
              TalentProfileService.putTalentProfile({ cvUrl: key }, profileId).catch((err) => dispatch(setErrorMess(err)));
            }
          })
          .catch((e) => dispatch(setErrorMess(e)));
      })
      .catch((e) => dispatch(setErrorMess(e)));
  };

  const handleAddTalent = async (profileData, preferenceData, cvUpload) => {
    dispatch(setLoading(true));

    try {
      let combinedData = {
        ...profileData,
        ...preferenceData,
      };

      const response = await AssistCreate.postAssistCreate({
        username: profileData.email,
        email: profileData.email,
      });

      if (cvUpload) {
        const reader = new FileReader();
        reader.readAsDataURL(cvUpload);
        reader.onloadend = async () => {
          const base64data = (reader.result as string).split(',')[1];
          const cvData = await CVParseService.postCV({
            DocumentAsBase64String: base64data,
            DocumentLastModified: new Date().toISOString().split('T')[0],
          });

          const skills = cvData.Value.ResumeData.Skills.Raw.slice(0, 5).map(skill => skill.Name);
          const title = cvData.Value.ResumeData.EmploymentHistory.Positions[0].JobTitle.Raw;
          const totalExperience = cvData.Value.ResumeData.EmploymentHistory.ExperienceSummary.MonthsOfWorkExperience;
          const currentCompany = cvData.Value.ResumeData.EmploymentHistory.Positions[0].Employer.Name.Raw;
          const summary = cvData.Value.ResumeData.EmploymentHistory.ExperienceSummary.Description;

          const education = cvData.Value.ResumeData.Education.EducationDetails.map(edu => ({
            provider: edu.SchoolName.Raw,
            qualification: edu.Degree.Raw,
          }));

          const experience = cvData.Value.ResumeData.EmploymentHistory.Positions.map(pos => ({
            title: pos.JobTitle.Raw,
            company: pos.Employer.Name.Raw,
            dateFrom: pos.StartDate.Date,
            dateTo: pos.EndDate.Date,
            summary: pos.Description,
          }));

          combinedData = {
            ...combinedData,
            skills,
            title,
            totalExperience,
            currentCompany,
            summary,
            education,
            experience,
          };
        };
        reader.onerror = () => {
          console.error('FileReader error');
        };
      }

      const profileRes = await TalentProfileService.postTalentAssistProfile(combinedData);
    } catch (err) {
      dispatch(setErrorMess(err));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleUpdateTalent = (profileId, profileData, preferenceData, cvUpload) => {
    dispatch(setLoading(true));
    const combinedData = { ...profileData, ...preferenceData };
    Promise.all([TalentProfileService.putAssistUpdateProfile(combinedData, profileId)])
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleClickFilterPolicy = (status) => {
    setPolicyFilter(status);
  };

  const handleClickFilterStatus = (status) => {
    setStatusFilter(status);
  };

  const onCloseRateFilter = () => {
    setIsOpenFilterRateModal({
      isOpen: false,
      type: null,
    });
  };

  const routeSeeFullProfile = (candidateID, jobId) => {
    dispatch(push(`/jobs/${jobId}/candidates/${candidateID}`));
  };

  const handleDownloadCv = (talent) => {
    dispatch(setLoading(true));
    TalentProfileService.getCv(talent?.cvUrl)
      .then((res) => {
        const fileType = (res as any)?.type;
        let fileName = `${talent?.firstName}-${talent?.lastName}-CV.docx`;
        if (fileType === 'application/pdf') {
          fileName = `${talent?.firstName}-${talent?.lastName}-CV.pdf`;
        }
        const myFile = new File([res as BlobPart], fileName, {
          type: (res as any)?.type,
        });
        saveAs(myFile, fileName);
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  const { onOpen: openAssignOpportunity } = useModal('AssignOpportunity');
  const webSocket = useWebSocket();
  const history = useHistory();
  const { user } = useSelector((state: ReducerType) => state.user);

  return (
    <>
      <div className={clsx('page-header header-filter', classes.pageHeader)}>
        <div className="page-header-image" />
        <div className={clsx('content mb-0', classes.content)}>
          <Container className={classes.container}>
            <p className={classes.title}>CANDIDATES</p>
            <p className={classes.subTitle}>All candidates your organisation has connected with.</p>
            <div className={classes.leftAlignDropdowns}>
              <Button
                color="primary"
                size="sm"
                className={classes.filtersButton}
                onClick={() => {
                  setAreFiltersVisible(!areFiltersVisible);
                }}
              >
                Filters
              </Button>
              <Button size="sm" className={classes.btnClearFilter} onClick={handleClearFilters}>
                <i className="fas fa-minus-circle mr-1" />
                Clear filters
              </Button>
            </div>
            <div className={classes.tableHeaderContainer}>
              {areFiltersVisible && (
                <div className={classes.filterWrapper}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearch();
                    }}
                  >
                    <CustomInput
                      endIcon={<i className={clsx('fa fa-search', classes.searchIcon)} onClick={handleSearch} />}
                      placeholder="Search name or title..."
                      onChange={(e) => {
                        setValueOfSearchInput(e.target.value);
                      }}
                    />
                  </form>

                  <CustomDatePicker
                    name={`filterAvailability`}
                    placeholder="Filter on Availability"
                    dateFormat="dd/MM/yyyy"
                    errorMessage={''}
                    onChange={(date) => setAvailabilityFilter(date)}
                    className={classes.searchNameInput}
                    selected={availabilityFilter}
                  />

                  <CustomDropdown
                    title="Filter on Country"
                    options={allCountries}
                    selectedOption={countryFilter}
                    onChange={(selectedCountry) => {
                      setCountryFilter(selectedCountry);
                      setCityFilter(''); // Reset city filter when country changes
                    }}
                  />
                  <CustomDropdown
                    title="Filter on City"
                    options={citiesInSelectedCountry}
                    selectedOption={cityFilter}
                    onChange={(selectedCity) => setCityFilter(selectedCity)}
                  />
                </div>
              )}
            </div>
            <div className={classes.tableContainer}>
              <Table className={classes.table} responsive>
                <thead>
                  <tr>
                    <th>Candidate Name</th>
                    <th>Title</th>
                    <th>Location</th>
                    <th
                      className={clsx(classes.rateFilter)}
                      onClick={() => setIsOpenFilterRateModal({ isOpen: true, type: 'max' })}
                    >
                      Hourly <i className="fas fa-filter" />
                    </th>
                    <th
                      className={clsx(classes.rateFilter)}
                      onClick={() => setIsOpenFilterRateModal({ isOpen: true, type: 'max' })}
                    >
                      Salary <i className="fas fa-filter" />
                    </th>
                    <th>Connection</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {talentsListRender?.length
                    ? talentsListRender.map((item, index) => {
                        const hasCorrespondingJob = jobList.some((job) => job.id === item.jobId);

                        return (
                          <>
                            <tr key={index}>
                              <td scope="row" onClick={() => handleToggleSubTable(index)}>
                                {item?.firstName} {item?.lastName}
                              </td>

                              <td scope="row" onClick={() => handleToggleSubTable(index)}>
                                {item?.title}
                              </td>
                              <td className={classes.dNoneMd}>
                                {UtilContractor.getCurrentWorkPolicy(item?.workPolicy)?.name === 'Work From Home'
                                  ? 'WFH'
                                  : UtilContractor.getCurrentWorkPolicy(item?.workPolicy)?.name}
                              </td>
                              <td scope="row" onClick={() => handleToggleSubTable(index)}>
                                {item?.maxRate ? `$${item?.maxRate}` : ''}
                              </td>
                              <td scope="row" onClick={() => handleToggleSubTable(index)}>
                                {item?.maxSalary ? `$${item?.maxSalary}` : ''}
                              </td>
                              <td scope="row" onClick={() => handleToggleSubTable(index)}>
                                {item?.jobId} | {item?.jobTitle}
                              </td>
                              <td className={clsx('text-center', classes.iconRotateWrapper)}>
                                <i
                                  className={clsx('fa fa-arrow-alt-circle-down', classes.rotate)}
                                  onClick={(e) => {
                                    handleRotateIcon(e);
                                    handleToggleSubTable(index);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={7} className={clsx('p-0', classes.subTable)}>
                                <Collapse isOpen={isOpenToggleArr[index]}>
                                  <div className={classes.collapseSubTable}>
                                    <Row tag="ul" className={clsx(classes.collapseInformation)}>
                                      <Col tag="li" xs={6} md={4}>
                                        <h2>Contact Number</h2>
                                        <p>
                                          {item?.mobile?.startsWith('+')
                                            ? item?.mobile
                                            : item?.mobile?.startsWith('0')
                                            ? `${item?.countryCode}${item?.mobile.substring(1)}`
                                            : `${item?.countryCode}${item?.mobile}`}
                                        </p>
                                      </Col>
                                      <Col tag="li" xs={6} md={4}>
                                        <h2>Email</h2>
                                        <p>{item?.email}</p>
                                      </Col>
                                      <Col tag="li" xs={6} md={4}>
                                        <h2 className={classes.dNoneMd}>Work Preference</h2>
                                        <p className={classes.dNoneMd}>{item?.workPolicy}</p>
                                      </Col>
                                      <Col tag="li" xs={6} md={4}>
                                        <h2>Address</h2>
                                        <p>
                                          {item?.region}, {item?.city} - {item?.country}
                                        </p>
                                      </Col>
                                      <Col tag="li" xs={6} md={4}>
                                        <h2>Availability</h2>
                                        <p>
                                          {item?.availableDate
                                            ? moment(item?.availableDate).format(`DD/MM/YYYY`)
                                            : `${item?.noticePeriod} weeks`}
                                        </p>
                                      </Col>
                                      <Col tag="li" xs={6} md={4}></Col>
                                    </Row>
                                    <div className={classes.collapseButton}>
                                      <UncontrolledDropdown nav className={classes.uncontrolledDropdown}>
                                        <DropdownToggle
                                          caret
                                          color="default"
                                          data-toggle="dropdown"
                                          id="navbarDropdownMenuLink2"
                                          nav
                                          onClick={(e) => e.preventDefault()}
                                          className={classes.dropdownWrapper}
                                        >
                                          <p className={classes.dropdownContent}>More</p>
                                        </DropdownToggle>
                                        <DropdownMenu
                                          aria-labelledby="navbarDropdownMenuLink2"
                                          className={classes.dropdownMenu}
                                          container="body"
                                        >
                                          {hasCorrespondingJob && (
                                            <DropdownItem onClick={() => routeSeeFullProfile(item?.id, item?.jobId)}>
                                              See Details
                                            </DropdownItem>
                                          )}
                                          {item?.cvUrl && (
                                            <DropdownItem onClick={() => handleDownloadCv(item)}>Download CV</DropdownItem>
                                          )}
                                          <DropdownItem onClick={() => openAssignOpportunity({ contractorId: item?.id })}>
                                            Assign to opportunity
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  </div>
                                </Collapse>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ''}
                </tbody>
              </Table>
            </div>
          </Container>
        </div>
        {/* <FooterTransparentEmployer /> */}
      </div>
      <AddEditTalent
        onClose={onCloseAddEditModal}
        isOpen={addEditModalOpen.isOpenModal}
        itemEdit={addEditModalOpen.editedItem}
        handleAddTalent={handleAddTalent}
        handleUpdateTalent={handleUpdateTalent}
      />
      <RateFilter
        onClose={onCloseRateFilter}
        isOpen={isOpenFilterRateModal.isOpen}
        rateType={isOpenFilterRateModal.type}
        updateFilterMaxRate={(value) => setMaxRateFilter(value)}
        updateFilterMinRate={(value) => setMinRateFilter(value)}
        defaultFilterRate={{
          minRateFilter: minRateFilter || 30,
          maxRateFilter: maxRateFilter || 300,
        }}
      />
    </>
  );
});

export default Candidates;
