import React, { useState, memo, useEffect } from 'react';
import { Container, Button, Form, FormGroup, Label, Input, Card, CardBody, CardTitle, Alert } from 'reactstrap';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { ReducerType } from "redux/reducers";
import classes from './styles.module.scss';
import clsx from 'clsx';

const stripePromise = loadStripe('pk_live_51LynwqC0FFvJocjAZLDDN3ExmXnYgl8QFzU9Wr1yUQSQTnKNOnkniVQTXi5mqf7iKdQGCBV6ZoKIZRvR8nyhVvDf00vQi3ryYg');

interface Props {}

const PaymentForm: React.FC = () => {
  const { user } = useSelector((state: ReducerType) => state.user);
  const stripe = useStripe();
  const elements = useElements();
  const [billingPeriod, setBillingPeriod] = useState('monthly');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null);
  const { setting } = useSelector((state: ReducerType) => state.employer);
  useEffect(() => {
    // Fetch the user's subscription status from your server
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await fetch('/api/subscription-status', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ customerId: setting.idBasicProfile }),
        });

        const data = await response.json();
        setSubscriptionStatus(data.status);
      } catch (error) {
        console.error('Failed to fetch subscription status:', error);
      }
    };

    fetchSubscriptionStatus();
  }, [user]);

  const handleBillingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingPeriod(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      setErrorMessage('Stripe has not loaded yet.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    const cardElement = elements.getElement(CardElement);
    const fullName = `${setting?.firstName || ''} ${setting?.lastName || ''}`;
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement!,
        billing_details: {
          name: fullName,
          email: user?.email,
        },
      });

      if (error) {
        setErrorMessage(error.message || 'An error occurred.');
        setIsLoading(false);
        return;
      }

      // Call your backend to create the subscription
      const response = await fetch('/create-subscription', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          billingPeriod,
          customerId: setting.idBasicProfile,
        }),
      });

      const subscriptionData = await response.json();

      if (response.ok) {
        // Handle the payment intent status
        const { status, clientSecret } = subscriptionData;
        if (status === 'requires_action' || status === 'requires_confirmation') {
          const { error: confirmError } = await stripe.confirmCardPayment(clientSecret);
          if (confirmError) {
            setErrorMessage(confirmError.message);
            setIsLoading(false);
            return;
          }
        }
        setSubscriptionStatus('active');
        setErrorMessage('Subscription successfully created.');
      } else {
        setErrorMessage(subscriptionData.error.message);
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {subscriptionStatus === 'active' ? (
        <Alert color="success">Your subscription is active.</Alert>
      ) : subscriptionStatus === 'past_due' ? (
        <Alert color="danger">Your payment has failed. Please update your payment details to restore your subscription.</Alert>
      ) : (
        <>
          <FormGroup>
            <Label for="billingPeriod">Choose Your Billing Period</Label>
            <div>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="billingPeriod"
                    value="monthly"
                    checked={billingPeriod === 'monthly'}
                    onChange={handleBillingChange}
                  />
                  Monthly Billing
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="billingPeriod"
                    value="yearly"
                    checked={billingPeriod === 'yearly'}
                    onChange={handleBillingChange}
                  />
                  Yearly Billing
                </Label>
              </FormGroup>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="cardDetails">Credit Card Details</Label>
            <CardElement id="cardDetails" />
          </FormGroup>
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          <Button color="primary" disabled={isLoading || !stripe}>
            {isLoading ? 'Processing...' : 'Update Payment'}
          </Button>
        </>
      )}
    </Form>
  );
};


const PaymentPage = memo((props: Props) => {
  // console.log('PaymentPage rendered');
  const { setting } = useSelector((state: ReducerType) => state.employer);
  console.log("setting", setting)
  return (
    <Elements stripe={stripePromise}>
      <Container className={classes.container}>
      <Card>
        <CardBody>
          <CardTitle tag="h5">Update Your Payment Details</CardTitle>
          {setting?.admin ? (
            <PaymentForm />
          ) : (
            <Alert color="warning">
              Please Contact Your Necta Administrator for Access
            </Alert>
          )}
        </CardBody>
      </Card>
      </Container>
    </Elements>
  );
});

export default PaymentPage;