import { memo, useMemo, useEffect } from "react";
import { Form, Button, Row, CardHeader, Card } from "reactstrap";
import ImageUpload from "components/CustomUpload";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "components/Common/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { BasicProfileService } from "services/Contractor/NewMember/BasicProfile";
import CustomTextarea from "components/CustomTextarea";
import { ImageService } from "services/Image";
import { ReducerType } from "redux/reducers";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
import password from "secure-random-password";
import { UserService } from "services/User";
import { setUserReducer } from "redux/reducers/User/actionTypes";
import { setProfileReducer } from "redux/reducers/Contractor/actionTypes";
import { UtilDefaultAvatar } from "utils/defaultAvatar";
// import { CVParseService } from "services/Contractor/NewMember/CVParse";
// import moment from "moment";

interface Props {}

interface BasicProfileFormData {
  id?: number;
  firstName: string;
  lastName: string;
  professionalTitle: string;
  currentCompany: string;
  linkedinUrl?: string;
  aboutYou: string;
  avatar?: File;
}

// eslint-disable-next-line no-empty-pattern
const BasicProfile = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile } = useSelector((state: ReducerType) => state.contractor);
  console.log(profile)
  const schema = useMemo(() => {
    return yup.object().shape({
      id: yup.number(),
      avatar: yup.mixed(),
      firstName: yup
        .string()
        .min(2, "At least 2 characters")
        .max(64, "At least 64 characters")
        .required("This field is required"),
      lastName: yup
        .string()
        .min(2, "At least 2 characters")
        .max(64, "At least 64 characters")
        .required("This field is required"),
      professionalTitle: yup
        .string()
        .min(2, "At least 2 characters")
        .max(64, "At least 64 characters")
        .required("This field is required"),
      // currentCompany: yup
      //   .string()
      //   .typeError("This field is required")
      //   .required("This field is required"),
      linkedinUrl: yup.string().nullable(),
      aboutYou: yup
        .string()
        .typeError("This field is required")
        .required("This field required field"),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<BasicProfileFormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (profile?.basicProfile) {
      reset({
        id: profile.basicProfile.id,
        firstName: profile.basicProfile.firstName,
        lastName: profile.basicProfile.lastName,
        professionalTitle: profile.basicProfile.title,
        currentCompany: profile.basicProfile.currentCompany,
        linkedinUrl: profile.basicProfile.linkedinUrl,
        aboutYou: profile.basicProfile.about,
      });
      if (profile.basicProfile.photoUrl) {
        ImageService.getImageBlob(profile.basicProfile.photoUrl)
          .then((res) => {
            setValue("avatar", blobToFile(res as Blob, "avatar.png"));
          })
          .catch((e) => dispatch(setErrorMess(e)));
      }
    }
  }, [dispatch, profile, reset, setValue]);

  const handleBack = () => {
    
    dispatch(push(routes.contractor.newMember.uploadCv));
  };

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob as any], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };

  // const postContractorInformationToSorven = (data) => {
  //   const userInformation = `name: ${data?.firstName} ${data?.lastName}\n Objective: ${data?.aboutYou}\n Skills: ${data?.professionalTitle}\n`;

  //   const convertToBase64 = btoa(userInformation);

  //   CVParseService.postCV({
  //     DocumentAsBase64String: convertToBase64,
  //     SkillsSettings: {
  //       Normalize: true,
  //       TaxonomyVersion: "",
  //     },
  //     IndexingOptions: { IndexId: user?.id, DocumentId: user?.id },
  //     DocumentLastModified: moment(new Date()).format("YYYY-MM-DD"),
  //   })
  //     .then((res) => {
  //       CVParseService.postUserInformation(
  //         {
  //           ResumeData: res?.Value?.ResumeData,
  //         },
  //         user?.id,
  //         user?.id
  //       ).catch((e) => dispatch(setErrorMess(e)));
  //     })
  //     .catch((e) => dispatch(setErrorMess(e)));
  // };

  // const updateUserChatEngine = (firstName, lastName, avt, basicProfile) => {
  //   UserChatEngineService.getUserChatEngine(user?.chatUserId)
  //     .then(async (res) => {
  //       const customJson = {
  //         ...JSON.parse(res?.custom_json),
  //         ...basicProfile,
  //       };
  //       let form_data = new FormData();
  //       form_data.append("username", user?.id.toString());
  //       form_data.append("first_name", firstName);
  //       form_data.append("last_name", lastName);
  //       if (avt) {
  //         form_data.append("avatar", avt, avt.name);
  //       } else {
  //         const defaultAvatar = await UtilDefaultAvatar.getFile();
  //         form_data.append("avatar", defaultAvatar, defaultAvatar.name);
  //       }
  //       form_data.append("secret", user?.chatSecret);
  //       form_data.append("custom_json", JSON.stringify(customJson));
  //       UserChatEngineService.patchUserChatEngine(
  //         user?.chatUserId,
  //         form_data
  //       ).catch((e) => dispatch(setErrorMess(e)));
  //     })
  //     .catch((e) => dispatch(setErrorMess(e)));
  // };

  const addSubscriberToMailchimp = (email, firstName, lastName) => {
    const headers = {
      "Authorization": `Bearer ${process.env.MAILCHIMP_API_KEY}`,
      "Content-Type": "application/json"
    };
    
    const data = {
      "email_address": email,
      "status": "subscribed",
      "tags": ["candidate"],
      "merge_fields": {
        "FNAME": firstName,
        "LNAME": lastName
      }
    };
  
    fetch(process.env.MAILCHIMP_URL, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
    .then(response => response.text())
    .then(text => console.log(`Mailchimp Response: ${text}`))
    .catch(error => console.error(`Failed to add subscriber to Mailchimp: ${error}`));
  };
  

  const onSubmit = async (data: BasicProfileFormData) => {
    dispatch(setLoading(true));
    //console.log(data?.firstName, data?.lastName, data?.professionalTitle, data?.aboutYou, data?.linkedinUrl, data?.currentCompany);
  
    try {
      const imageUrl = await ImageService.postImage(
        data?.avatar,
        `${user?.id}-${S3_UPLOAD_TYPE_PRIFIX.AVATAR}`
      );
  
      let basicProfileResponse;
  
      if (data?.id) {
        basicProfileResponse = await BasicProfileService.putBasicProfile(
          {
            firstName: data?.firstName,
            lastName: data?.lastName,
            title: data?.professionalTitle,
            about: data?.aboutYou,
            photoUrl: imageUrl,
            linkedInUrl: data?.linkedinUrl,
            currentCompany: data?.currentCompany,
          },
          data?.id
        );
      } else {
        basicProfileResponse = await BasicProfileService.postBasicProfile({
          firstName: data?.firstName,
          lastName: data?.lastName,
          title: data?.professionalTitle,
          about: data?.aboutYou,
          photoUrl: imageUrl,
          linkedInUrl: data?.linkedinUrl,
          currentCompany: data?.currentCompany,
        });
      }
  
      dispatch(setProfileReducer({ ...profile, basicProfile: basicProfileResponse }));
      // dispatch(push(routes.contractor.newMember.contactProfile));
      if (profile.experience && profile.experience.length > 1) {
        dispatch(push(routes.contractor.newMember.preference));
    } else {
        dispatch(push(routes.contractor.newMember.education));
    }
      
      // addSubscriberToMailchimp(user?.email, data?.firstName, data?.lastName);
    } catch (error) {
      dispatch(setErrorMess(error));
    } finally {
      dispatch(setLoading(false));
    }
  };
  

  return (
    <Card className={classes.card}>
      <CardHeader className={classes.cardHeader}>
        <p className={classes.title}>Basic Profile</p>
      </CardHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.content}>
          <Controller
            name="avatar"
            control={control}
            render={({ field }) => (
              <ImageUpload
                avatar={!field.value}
                value={field.value}
                onChange={(file) => field.onChange(file)}
                isDisabled={false}
              />
            )}
          />
          <CustomInput
            placeholder="First Name"
            type="text"
            autoComplete="off"
            inputRef={register("firstName")}
            errorMessage={errors.firstName?.message}
          />
          <CustomInput
            placeholder="Last Name"
            type="text"
            autoComplete="off"
            inputRef={register("lastName")}
            errorMessage={errors.lastName?.message}
          />
          <CustomInput
            placeholder="Job Title"
            type="text"
            autoComplete="off"
            inputRef={register("professionalTitle")}
            errorMessage={errors.professionalTitle?.message}
          />
          <CustomInput
            placeholder="Current Company"
            type="text"
            autoComplete="off"
            // inputRef={register("currentCompany")}
            errorMessage={errors.currentCompany?.message}
          />
          <CustomInput
            placeholder="LinkedIn Profile URL..."
            autoComplete="off"
            inputRef={register("linkedinUrl")}
            errorMessage={errors.linkedinUrl?.message}
          />
          <CustomTextarea
            className={classes.textarea}
            placeholder="Write a Professional Summary"
            autoComplete="off"
            inputRef={register("aboutYou")}
            errorMessage={errors.aboutYou?.message}
          />
        </div>
        <Row className={classes.buttonContainer}>
          <Button
            size="md"
            color="default"
            type="button"
            className={classes.btn}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            size="md"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Next
          </Button>
        </Row>
      </Form>
    </Card>
  );
});

export default BasicProfile;
