import { Fragment, memo, useMemo, useState } from "react";
import { Button, Card, CardBody, Form } from "reactstrap";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { ReducerType } from "redux/reducers";
import { UtilCvParsing } from "utils/cvParsing";
import { CVParseService } from "services/Contractor/NewMember/CVParse";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import CustomInput from "components/Common/CustomInput";
import clsx from "clsx";

interface Props {}

const UploadCv = memo((props: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile } = useSelector((state: ReducerType) => state.contractor);
  // console.log(profile)

  const schema = useMemo(() => {
    return yup.object().shape({
      cvUpload: yup.mixed().required("This field is required"),
      cvUploadFileBase64: yup.string(),
    });
  }, []);

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const handleBack = () => {
    dispatch(push(routes.contractor.newMember.basicProfile));
  };
  
  const onSubmitCv = async (data) => {
    dispatch(setLoading(true));
    await CVParseService.getUserIndex()
      .then(async (res) => {
        const arrIndexUser = res?.Value?.filter(
          (item) =>
            item?.Name === user?.id?.toString() && item?.IndexType === "Resume"
        );
        if (arrIndexUser.length === 0) {
          await CVParseService.createUserIndex(user?.id).then(
            async () =>
              await UtilCvParsing.cvParsing(dispatch, user, profile, data, false)
          );
        } else {
          await UtilCvParsing.cvParsing(dispatch, user, profile, data, false);
        }
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleFileInputChange = ({ target }) => {
    if (target?.files[0]) {
      setValue("cvUpload", target.files[0], { shouldValidate: true });
      const reader = new FileReader();
      reader.readAsDataURL(target.files[0]);
      reader.onload = function () {
        const temp = reader.result as string;
        const arr = temp.split(",");
        setValue("cvUploadFileBase64", arr[1]);
      };
    } else {
      setValue("cvUpload", null);
      setValue("cvUploadFileBase64", "");
    }
  };

  return (
    <Card className={classes.card}>
      <CardBody>
        <p className={classes.title}>Lets try and save you some time?</p>
        <div className={classes.disclaimerContainer}></div>
        <Fragment>
          <p className={classes.subTitle}>
            When a CV is uploaded, we try our best to create your profile. We
            do not store your CV but use it to fill out your Necta profile.
          </p>
          <Form onSubmit={handleSubmit(onSubmitCv)}>
            <div className={classes.cvContent}>
              <p className={classes.commonTitle}>Upload CV here:</p>
              <input
                className={classes.uploadFile}
                type="file"
                placeholder="Upload..."
                accept=".doc, .docx, .pdf"
                onChange={handleFileInputChange}
              />
              <span className="text-danger ml-3 mt-1 d-block text-left">
                {errors?.cvUpload?.message && errors?.cvUpload?.message}
              </span>
            </div>
            <div className={classes.buttonContainer}>
              <Button color="default" type="button" onClick={handleBack}>
                No, Thank You
              </Button>
              <Button color="primary" type="submit">
                Yes, Please
              </Button>
            </div>
          </Form>
        </Fragment>
      </CardBody>
    </Card>
  );
});

export default UploadCv;
