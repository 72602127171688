/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useMemo, useState } from "react";
import Logo from "../../../assets/img/logo.png";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Container,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ScrollNavbar from "components/Navbars/Contractor/ScrollNavbar";
import Footer from "components/Footers/FooterTransparent";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "components/Common/CustomInput";
import classes from "./styles.module.scss";
import userFactory from "services/userService/UserFactory";
import { Link } from "react-router-dom";
import routes from "routers/routes";
import { PASSWORD_REGREX, SIGN_UP_MODE, USER_TYPE } from "config/constants";
import CustomCheckbox from "components/Common/CustomCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "redux/reducers/Status/actionTypes";
import { push } from "connected-react-router";
import { ReducerType } from "redux/reducers";

interface SignupProps {}

// eslint-disable-next-line no-empty-pattern
const Signup = memo(({}: SignupProps) => {
  const [user, setUser] = useState(null);
  const [mode, setMode] = useState(SIGN_UP_MODE.CREATE);
  const [isResendEmail, setIsResendEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const userState = useSelector((state: ReducerType) => state.user);

  const schema = useMemo(() => {
    return mode === SIGN_UP_MODE.CREATE
      ? yup.object().shape({
          email: yup
            .string()
            .email("Please enter a valid email")
            .required("Email is required"),
          password: yup
            .string()
            .required("Password is required")
            .matches(
              PASSWORD_REGREX,
              "Your password must be at least 8 characters long, contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character"
            ),
          acceptTerm: yup.boolean().oneOf([true], "This field is required"),
        })
      : yup
          .object()
          .shape({ code: yup.string().required("This field is required") });
  }, [mode]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    if (userState?.user) {
      if (userState?.user?.profileCompleted) {
        dispatch(push(routes.contractor.profile));
      } else {
        dispatch(push(routes.contractor.newMember.root));
      }
    }
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowModal = () => setModal(!modal);

  const handleAcceptTerm = () => {
    setValue("acceptTerm", true);
    setModal(!modal);
  };

  const handleDeclineTerm = () => {
    setValue("acceptTerm", false);
    setModal(!modal);
  };

  const handleResendEmail = async () => {
    dispatch(setLoading(true));
    await userFactory
      .generate()
      .resendConfimationCode(user)
      .then(() => {
        setIsResendEmail(true);
      })
      .catch((e: any) => {
        setErrorMessage(e?.message);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const handleConfirm = async (data) => {
    dispatch(setLoading(true));
    await userFactory
      .generate()
      .confirm(user, data?.code)
      .then(() => {
        dispatch(push(routes.contractor.login));
      })
      .catch((e: any) => {
        setErrorMessage(e?.message);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const handleSignUp = async (data) => {
    dispatch(setLoading(true));
    await userFactory
      .generate()
      .register(data?.email, data?.password, USER_TYPE.CONTRACTOR)
      .then((result) => {
        setUser(result.username);
        setMode(SIGN_UP_MODE.CONFIRM);
      })
      .catch((e: any) => {
        setErrorMessage(e?.message);
        setUser(null);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <>
      <ScrollNavbar />
      <div className="page-header header-filter" filter-color="yellow">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/login.jpg") + ")",
          }}
        />
        <div className={classes.content}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card className={`card-login ${classes.card}`}>
                  {!user ? (
                    <Form
                      action=""
                      className="form"
                      method=""
                      onSubmit={handleSubmit(handleSignUp)}
                    >
                      <CardHeader className="text-center">
                        <div className={classes.logoContainer}>
                          <img alt="Logo" src={Logo} width={150}/>
                        </div>
                        <p className={classes.signupTitle}>Talent Sign Up</p>
                      </CardHeader>
                      <CardBody className={classes.cardBody}>
                        <div className={classes.inputContainer}>
                          <CustomInput
                            startIcon={
                              <i className="now-ui-icons users_circle-08" />
                            }
                            placeholder="Email"
                            type="email"
                            autoComplete="off"
                            inputRef={register("email")}
                            errorMessage={errors.email?.message}
                          />
                          <CustomInput
                            startIcon={<i className="fa fa-text-height" />}
                            placeholder="Password"
                            type="password"
                            inputRef={register("password")}
                            errorMessage={errors.password?.message}
                          />
                          <CustomCheckbox
                            content={
                              <>
                                I agree to the{" "}
                                <a
                                  href="javascript:void(0);"
                                  onClick={handleShowModal}
                                >
                                  terms and conditions
                                </a>
                                <Modal
                                  isOpen={modal}
                                  toggle={handleShowModal}
                                  className={classes.termModal}
                                >
                                  <ModalHeader toggle={handleShowModal}>
                                    Terms And Conditions
                                  </ModalHeader>
                                  <ModalBody>
                                    Please read this agreement carefully before
                                    accessing or using the information and
                                    services available through the Necta website
                                    (“Site”). By accessing or using the Site,
                                    you agree to be bound by the terms and
                                    conditions below. Necta may modify this
                                    agreement at any time, and such
                                    modifications shall be effective immediately
                                    upon posting the modified agreement on the
                                    Site. Advertiser terms and conditions must
                                    be accepted and adhered to by Advertisers
                                    who use Necta's services and the Site. Necta
                                    also has a comprehensive privacy policy for
                                    users of the Site.
                                  </ModalBody>
                                  <ModalFooter className="d-flex justify-content-end">
                                    <Button
                                      color="primary"
                                      onClick={handleAcceptTerm}
                                    >
                                      Accept
                                    </Button>
                                    <Button
                                      color="default"
                                      onClick={handleDeclineTerm}
                                      className="ml-3"
                                    >
                                      Decline
                                    </Button>
                                  </ModalFooter>
                                </Modal>
                                .
                              </>
                            }
                            inputRef={register("acceptTerm")}
                            errorMessage={errors.acceptTerm?.message}
                          />
                          <span className={`text-danger ${classes.text}`}>
                            {errorMessage}
                          </span>
                        </div>
                        <CardFooter className={classes.cardFooter}>
                          <Button
                            block
                            className="btn"
                            color="primary"
                            type="submit"
                            size="md"
                          >
                            Get Started
                          </Button>
                          <div className={classes.btnHaveAccountContainer}>
                            <Button
                              className={classes.btnHaveAccount}
                              tag={Link}
                              to={routes.contractor.login}
                              color="neutral"
                            >
                              I have an account
                            </Button>
                          </div>
                        </CardFooter>
                      </CardBody>
                      <CardFooter className="text-center"></CardFooter>
                    </Form>
                  ) : (
                    <Form
                      action=""
                      className="form"
                      method=""
                      onSubmit={handleSubmit(handleConfirm)}
                    >
                      <CardHeader className="text-center">
                        <div className="logo-container">
                          <img alt="Logo" src={Logo} />
                        </div>
                        <p className={classes.signupTitle}>
                          Confirm your email
                        </p>
                      </CardHeader>
                      <CardBody className={classes.cardBody}>
                        <div className={classes.inputCodeContainer}>
                          <p className={classes.text}>
                            {isResendEmail
                              ? "New email has been sent"
                              : "The code has been sent to your email"}
                          </p>
                          <CustomInput
                            placeholder="Verification code"
                            type="text"
                            autoComplete="off"
                            inputRef={register("code")}
                            errorMessage={errors.code?.message}
                          />
                          <p className={classes.text}>
                            <span onClick={handleResendEmail}>
                              Resend email?
                            </span>
                          </p>
                          <span className={`text-danger ${classes.text}`}>
                            {errorMessage}
                          </span>
                        </div>
                        <CardFooter className={classes.cardFooter}>
                          <Button
                            className="btn-round"
                            color="primary"
                            type="submit"
                            size="lg"
                          >
                            Submit
                          </Button>
                          <div className={classes.btnHaveAccountContainer}>
                            <Button
                              className={classes.btnHaveAccount}
                              tag={Link}
                              to={routes.contractor.login}
                              color="neutral"
                            >
                              I have an account
                            </Button>
                          </div>
                        </CardFooter>
                      </CardBody>
                      <CardFooter className="text-center"></CardFooter>
                    </Form>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
});

export default Signup;
