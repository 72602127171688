import axios from "axios";

export class CVParseService {
  static async postCV(data) {
    return axios
      .post(
        `${process.env.REACT_APP_API_PARSER_ENDPOINT}/parser/resume`,
        data,
        {
          headers: {
            "Sovren-AccountId":
              process.env.REACT_APP_API_PARSER_SOVREN_ACCOUNDID,
            "Sovren-ServiceKey":
              process.env.REACT_APP_API_PARSER_SOVREN_SERVICEKEY,
          },
        }
      )
      .then((res) => {
        // console.log('Response from postCV:', res.data);
        return Promise.resolve(res.data);

      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);

      });
      
  }

  static async postUserInformation(data, indexId, documentId) {
    return axios
      .post(
        `${process.env.REACT_APP_API_PARSER_ENDPOINT}/index/${indexId}/resume/${documentId}`,
        data,
        {
          headers: {
            "Sovren-AccountId":
              process.env.REACT_APP_API_PARSER_SOVREN_ACCOUNDID,
            "Sovren-ServiceKey":
              process.env.REACT_APP_API_PARSER_SOVREN_SERVICEKEY,
          },
        }
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getUserInformation(indexId, documentId) {
    return axios
      .get(
        `${process.env.REACT_APP_API_PARSER_ENDPOINT}/index/${indexId}/resume/${documentId}`,
        {
          headers: {
            "Sovren-AccountId":
              process.env.REACT_APP_API_PARSER_SOVREN_ACCOUNDID,
            "Sovren-ServiceKey":
              process.env.REACT_APP_API_PARSER_SOVREN_SERVICEKEY,
          },
        }
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async updateUserDefinedTags(data, indexId, documentId) {
    return axios
      .patch(
        `${process.env.REACT_APP_API_PARSER_ENDPOINT}/index/${indexId}/resume/${documentId}`,
        data,
        {
          headers: {
            "Sovren-AccountId":
              process.env.REACT_APP_API_PARSER_SOVREN_ACCOUNDID,
            "Sovren-ServiceKey":
              process.env.REACT_APP_API_PARSER_SOVREN_SERVICEKEY,
          },
        }
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async createUserIndex(data) {
    return axios
      .post(
        `${process.env.REACT_APP_API_PARSER_ENDPOINT}/index/${data}`,
        {
          IndexType: "Resume",
        },
        {
          headers: {
            "Sovren-AccountId":
              process.env.REACT_APP_API_PARSER_SOVREN_ACCOUNDID,
            "Sovren-ServiceKey":
              process.env.REACT_APP_API_PARSER_SOVREN_SERVICEKEY,
          },
        }
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getUserIndex() {
    return axios
      .get(`${process.env.REACT_APP_API_PARSER_ENDPOINT}/index`, {
        headers: {
          "Sovren-AccountId": process.env.REACT_APP_API_PARSER_SOVREN_ACCOUNDID,
          "Sovren-ServiceKey":
            process.env.REACT_APP_API_PARSER_SOVREN_SERVICEKEY,
        },
      })
      .then((res) => {
        
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
