import { Fragment, memo, useEffect, useState, useRef } from "react";
import routes from "routers/routes";
import classes from './styles.module.scss';
import clsx from 'clsx';
import ContractorAuthRoute from "routers/ContractorAuthRoute";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import Messenger from "./components/Messenger";
import Dashboard from "./components/Dashboard";
import Users from "./components/Users";
import Myjobs from "./components/Myjobs";
import MyClientjobs from "./components/MyClientjobs";
import Employees from "./components/Employees";
import Candidates from "./components/Candidates";
import Setting from "./components/Setting";
import Integration from "./components/Integration";
import OrganizationChart from "./components/OrganizationChart";
import Profile from "./components/Profile";
import ProfileSetting from "./components/Mysettings";
import Billing from "./components/Billing";
import ListingJob from "./components/ListingJob";
import Timesheet from "./components/Timesheet";
import Contractor from "./components/Contractor";
import JobTemplates from "./components/JobTemplates";
import PaymentPage from "./components/Payment";
import { EMPLOYER_PAGE } from "config/constants";
import FooterEmployerMobile from "components/Footers/FooterEmployerMobile";
import Talent from "./components/Talent";
import { useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import PerfectScrollbar from "perfect-scrollbar";
import NotificationAlert from "react-notification-alert";
import { Container } from "reactstrap";
import JobDetail from "./components/Myjobs/JobDetail";
import ClientJobDetail from "./components/MyClientjobs/JobDetail"
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import "assets/scss/now-ui-dashboard.scss?v=1.5.0";
import FeedbackButton from "components/Feedback"
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

var ps;

interface Props {}

const Admin = memo((props: Props) => {
  const { user } = useSelector((state: ReducerType) => state.user);
  // console.log(user)
  const { setting } = useSelector((state: ReducerType) => state.employer);
  const location = useLocation();
  const [sidebarMini, setSidebarMini] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState('');
  const notificationAlert = useRef<any>(null);
  const mainPanel = useRef<HTMLDivElement>(null);
  const stripeKey = process.env.REACT_APP_STRIPE_KEY;
  const stripePromise = loadStripe(stripeKey);
  // console.log('Stripe Publishable Key:', stripeKey);
  useEffect(() => {
    if (setting?.colour) {
      setBackgroundColor(setting?.colour);
      document.documentElement.style.setProperty('--primary-color', "black");
    }
  }, [setting]); 

  useEffect(() => {
    // Ensure sidebar is always minimized
    document.body.classList.add("sidebar-mini");
  }, []);

  const getActiveRoute = (routes) => {
    let activeRoute = "NECTA";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  return (
    <div className={clsx("wrapper", classes.wrapper)}>
      <Elements stripe={stripePromise}>
      {backgroundColor && (
        <>
        <Sidebar
          {...props}
          backgroundColor={backgroundColor}
        />
    
      <div className={clsx("main-panel", classes['main-panel'])} ref={mainPanel}>
        <AdminNavbar {...props} brandText={getActiveRoute(routes)} />
        
        <Switch>
          <ContractorAuthRoute path={routes.employer.admin.dashboard} render={(props) => <Dashboard {...props} />} />
          {setting?.admin && <ContractorAuthRoute path={routes.employer.admin.users} render={(props) => <Users {...props} />} />}
          <ContractorAuthRoute path={routes.employer.admin.myjobs} exact render={(props) => <Myjobs {...props} />} />
          <ContractorAuthRoute path={routes.employer.admin.jobtemplates} exact render={(props) => <JobTemplates {...props} />} />
          {user?.role === 7 && <ContractorAuthRoute path={routes.employer.admin.myClientjobs} exact render={(props) => <MyClientjobs {...props} />} />}
          <ContractorAuthRoute path={routes.employer.admin.listingJob} render={(props) => <ListingJob {...props} />} />
          <ContractorAuthRoute path={routes.employer.admin.messenger} render={(props) => <Messenger {...props} />} />
          <ContractorAuthRoute path={routes.employer.admin.candidates} render={(props) => <Candidates {...props} />} />
          <ContractorAuthRoute path={routes.employer.admin.timesheets} render={(props) => <Timesheet {...props} />} />
          <ContractorAuthRoute path={routes.employer.admin.organizationchart} render={(props) => <OrganizationChart {...props} />} />
          <ContractorAuthRoute path={routes.employer.admin.billing} render={(props) => <Billing {...props} />} />
          {setting?.admin && <ContractorAuthRoute path={routes.employer.admin.settings} render={(props) => <Setting {...props} />} />}
          {setting?.admin && <ContractorAuthRoute path={routes.employer.admin.integration} render={(props) => <Integration {...props} />} />}
          <ContractorAuthRoute path={routes.employer.admin.talent} render={(props) => <Talent {...props} />} />
          <ContractorAuthRoute path={routes.employer.admin.employees} render={(props) => <Employees {...props} />} />
          <ContractorAuthRoute path={routes.employer.admin.profile} render={(props) => <Profile {...props} />} />
          <ContractorAuthRoute path={routes.employer.admin.payment} render={(props) => <PaymentPage {...props} />} />
          <ContractorAuthRoute path={routes.employer.admin.contractor} render={(props) => <Contractor {...props} />} />
          <ContractorAuthRoute path={routes.employer.admin.mysetting} render={(props) => <ProfileSetting {...props} />} />
          <ContractorAuthRoute path={routes.employer.admin.jobDetail(':jobId')} component={JobDetail} />
          {user?.role === 7 && <ContractorAuthRoute path={routes.employer.admin.clientJobDetail(':jobId')} component={ClientJobDetail} />}
          <Redirect from={routes.employer.admin.root} to={routes.employer.admin.dashboard} />
         
        </Switch>
        <FeedbackButton />
        {/* {window.location.href.indexOf("full-screen-maps") !== -1 ? null : <FooterEmployerMobile />} */}
      </div>
      </>
       )}
       </Elements>
    </div>
  );
});

export default Admin;
