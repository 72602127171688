import React, { useState, memo, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import classes from "./styles.module.scss";
import FacebookLogin from 'components/Social/facebook';
import { ReducerType } from "redux/reducers";
import { OrganizationService } from "services/Employer/NewMember/Organization";
import { useSelector } from 'react-redux';

interface Props {}

interface Field {
  label: string;
  placeholder?: string;
  type: "text" | "email" | "password" | "checkbox" | "select"; // Specify allowed types
  options?: string[]; // For dropdown fields
  fieldName: any;
}

interface Integration {
  name: string;
  code: string;
  logo: string;
  info: string;
  fields: Field[];
} 

const Integration = memo((props: Props) => {
  const [activeIntegration, setActiveIntegration] = useState<number | null>(null);
  const [enabledIntegrations, setEnabledIntegrations] = useState<{ [key: string]: boolean }>({});
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string }>({});
  const [initialValues, setInitialValues] = useState<{ [key: string]: string }>({});
  const facebookLoginRef = useRef<any>(null);
  const { setting } = useSelector((state: ReducerType) => state.employer);

  useEffect(() => {
    const getOrgIntegration = async () => {
      try {
        const response = await OrganizationService.getIntegration(setting?.idOrganisation);
        console.log('Response:', response);
  
        // Initialize enabled integrations based on the response
        const initialIntegrations: { [key: string]: boolean } = {
          ats: response.ats || false,
          linkedin: response.linkedin || false,
          seek: response.seek || false,
          trademe: response.trademe || false,
          dogoodjobs: response.dogoodjobs || false,
          talent: response.talent || false,
          jobsora: response.jobsora || false,
          teams: response.teams || false,
        };
  
        // Initialize selected values for the fields
        const initialValues: { [key: string]: string } = {
          ats_email: response.ats_email || '',
          ats_chosen: response.ats_chosen || '',
          linkedin_max_budget: response.linkedin_max_budget || '',
          linkedin_option: response.linkedin_option || '',
          seek_option: response.seek_option || '',
          trademe_option: response.trademe_option || '',
          dogoodjobs_option: response.dogoodjobs_option || '',
          teamswebhook: response.teamswebhook || '',
        };
  
        setEnabledIntegrations(initialIntegrations); // Set enabled integrations
        setSelectedValues(initialValues); // Set the values to the selectedValues state
        setInitialValues(initialValues);  // For comparison
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    };
  
    if (setting?.idOrganisation) {
      getOrgIntegration();
    }
  }, [setting?.idOrganisation]);
  
  
  
  

  const toggleIntegration = async (code: string, enable: boolean) => {
    try {
      // Explicitly convert false to string for testing
      const changes = { [code]: enable ? true : 'false' };
  
      // Make the PUT request with the specific integration code and value
      await OrganizationService.putIntegration(changes, setting?.idOrganisation);
      console.log(changes);
  
      // Update the state to reflect the new enabled/disabled status
      setEnabledIntegrations((prevState) => ({
        ...prevState,
        [code]: enable,
      }));
    } catch (error) {
      console.error('Error toggling integration:', error);
    }
  };
  

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string
  ) => {
    const { value } = e.target;
  
    // Debugging to check if input change is being captured
    console.log(`Field: ${fieldName}, Value: ${value}`);
  
    setSelectedValues((prevState) => ({
      ...prevState,
      [fieldName]: value, // Update the selectedValues state with the new value
    }));
  };
  
  
  const fieldToDbMapping: { [key: string]: string } = {
    'ATS-Email for all jobs': 'ats_email',
    'ATS-Select ATS': 'ats_chosen',
    'linkedin-Maximum budget per listing': 'linkedin_max_budget',
    'linkedin-Option': 'linkedin_option',
    'seek-Option': 'seek_option',
    'trademe-Option': 'trademe_option',
    'dogoodjobs-Option': 'dogoodjobs_option',
    'teams-webhook': 'teamswebhook',
  };


  const saveIntegration = async (integrationCode: string) => {
    try {
      const changes: { [key: string]: string | boolean } = {
        [integrationCode]: true, // Enable the selected integration
      };
  
      console.log('Selected Values:', selectedValues); // Log selectedValues for debugging
  
      // Iterate over selectedValues and directly use the keys as database field names
      Object.keys(selectedValues).forEach((key) => {
        // Ensure the value is not an empty string or undefined before adding it to changes
        if (selectedValues[key]?.trim() !== "") {
          changes[key] = selectedValues[key];
        } else {
          console.warn(`Skipping field: ${key} as it doesn't have a valid value.`);
        }
      });
  
      console.log('Changes to Send:', changes); // Log the final changes object for debugging
  
      // Only send the request if there are changes besides the integration enabled flag
      if (Object.keys(changes).length > 1) {
        await OrganizationService.putIntegration(changes, setting?.idOrganisation);
        console.log("Integration updated successfully!");
  
        // Close the card and refresh the data
        setActiveIntegration(null);  // Close the active integration
        await refreshIntegrationData();  // Refresh the integration data to update highlights
      } else {
        console.warn('No valid changes to send.');
      }
    } catch (error) {
      console.error('Error saving integration:', error);
    }
  };
  
  const disableIntegration = async (integrationCode: string) => {
    try {
      const changes: { [key: string]: string | boolean } = {
        [integrationCode]: false, // Disable the integration
      };
  
      // Clear the integration fields for the specified integration
      const updatedSelectedValues = { ...selectedValues };
  
        Object.keys(updatedSelectedValues).forEach((key) => {
            if (key.startsWith(integrationCode)) {
                updatedSelectedValues[key] = null;  // Set the field to null in the state
                changes[key] = null;  // Ensure the field is set to null in the changes object as well
            }
        });

  
      setSelectedValues(updatedSelectedValues);  // Clear the field values in the state
  
      // Make the PUT request to update the integration with cleared fields
      await OrganizationService.putIntegration(changes, setting?.idOrganisation);
      console.log(changes)
      console.log(`${integrationCode} integration disabled and cleared successfully!`);
  
      // Refresh the data to reflect changes and close the card
      await refreshIntegrationData();
      setActiveIntegration(null);  // Close the card
    } catch (error) {
      console.error(`Error disabling ${integrationCode}:`, error);
    }
  };
  
  // Function to refresh integration data from the server
  const refreshIntegrationData = async () => {
    try {
      const response = await OrganizationService.getIntegration(setting?.idOrganisation);
      console.log('Refreshed Response:', response);
  
      // Update enabledIntegrations and selectedValues after refresh
      const initialIntegrations: { [key: string]: boolean } = {
        ats: response.ats || false,
        linkedin: response.linkedin || false,
        seek: response.seek || false,
        trademe: response.trademe || false,
        dogoodjobs: response.dogoodjobs || false,
        talent: response.talent || false,
        jobsora: response.jobsora || false,
        teams: response.teams || false,
      };
  
      const initialValues: { [key: string]: string } = {
        ats_email: response.ats_email || '',
        ats_chosen: response.ats_chosen || '',
        linkedin_max_budget: response.linkedin_max_budget || '',
        linkedin_option: response.linkedin_option || '',
        seek_option: response.seek_option || '',
        trademe_option: response.trademe_option || '',
        dogoodjobs_option: response.dogoodjobs_option || '',
        teamswebhook: response.teamswebhook || '',
      };
  
      setEnabledIntegrations(initialIntegrations);  // Refresh enabled integrations
      setSelectedValues(initialValues);  // Refresh field values
    } catch (error) {
      console.error('Error refreshing integration data:', error);
    }
  };

  
  
  
  
  
  
  
  const atsOptions = ["Snaphire", "Lever", "Workable", "BambooHR", "Other"];
  const listingOptions = ["All Jobs", "Per Job", "Disable"];

  const integrations: Integration[] = [
    {
      name: "Applicant Tracking System",
      code: "ATS",
      logo: "https://flatfeerecruiter.co.uk/app/uploads/2023/05/faq_Category_Headers_ATS.png",
      info: "Enable Recruiter Copilot from another ATS.",
      fields: [
        {
          label: "Email for all jobs",
          placeholder: "me@example.com",
          type: "text",
          fieldName: "ats_email"
        },
        {
          label: "Select ATS",
          type: "select",
          options: atsOptions,
          fieldName: "ats_chosen"
        }
      ]
    },
    {
      name: "Microsoft Teams",
      code: "teams",
      logo: "https://play-lh.googleusercontent.com/jKU64njy8urP89V1O63eJxMtvWjDGETPlHVIhDv9WZAYzsSxRWyWZkUlBJZj_HbkHA",
      info: "Integrate Microsoft Teams for greater teamwork.",
      fields: [
        {
          label: "Enter the webhook from the Teams Channel, this will override emails from Necta.",
          placeholder: "",
          type: "text",
          fieldName: "teamswebhook"
        }
      ]
    },
    {
      name: "LinkedIn Jobs",
      code: "linkedin",
      logo: "https://upload.wikimedia.org/wikipedia/commons/0/01/LinkedIn_Logo.svg",
      info: "Receive applications from Linkedin. Necta will post on behalf.",
      fields: [
        {
          label: "Maximum budget per listing",
          placeholder: "200",
          type: "text",
          fieldName: "linkedin_max_budget"
        },
        {
          label: "Choosing all Jobs will charge at max budget per listing, or you can select to post individually when needed.",
          type: "select",
          options: listingOptions,
          fieldName: "linkedin_option"
        }

      ]
    },
    {
      name: "SEEK",
      code: "seek",
      logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiqMadfN52EKOCdQAYRW16B22yO9zf8pqL_Q&s",
      info: "Upgrade to allow posting to Seek. Necta will post on behalf. ",
      fields: [
        {
          label: "Choosing all Jobs will charge $300 per listing posted, or you can select to post individually when needed.",
          type: "select",
          options: listingOptions,
          fieldName: "seek_option"          
        }
      ]
    },
    {
      name: "TradeMe Jobs",
      code: "trademe",
      logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEfR9QRfXfgTJgnQ692-wX8e1FCyFzfoAVJA&s",
      info: "Promote job listings to TradeMe. Necta will post on behalf.",
      fields: [        {
        label: "Choosing all Jobs will charge $99 per listing posted, or you can select to post individually when needed.",
        type: "select",
        options: listingOptions,
        fieldName: "trademe_option"
      }]
    },
    {
      name: "Do Good Jobs",
      code: "dogoodjobs",
      logo: "https://pbs.twimg.com/profile_images/1623417003527802880/Y8vkNcwI_400x400.jpg",
      info: "For Jobs that work towards positive social and environmental change. Necta will post on behalf.",
      fields: [        {
        label: "Choosing all Jobs will charge $149 per listing posted, or you can select to post individually when needed.",
        type: "select",
        options: listingOptions,
        fieldName: "dogoodjobs_option"
      }]
    },
    
    {
      name: "Talent.com",
      code: "talent",
      logo: "https://employers.talent.com/hubfs/t_square_purplebg.png",
      info: "Enable and use the Talent.com job postings. Necta will post on behalf.",
      fields: []
    },
    {
      name: "Jobsora",
      code: "jobsora",
      logo: "https://media.licdn.com/dms/image/v2/C4E0BAQHPOShai884Tg/company-logo_200_200/company-logo_200_200/0/1678112694442/jobsora_logo?e=2147483647&v=beta&t=KUX-KcAIzeoJQujLP0Tct6_JYt_qzT35SyQrNWOLapw",
      info: "Enable and use the Jobsora job postings. Necta will post on behalf.",
      fields: []
    }
  ];

 

  const renderField = (field: Field, index: number) => {
    if (field.type === "select" && field.options) {
      return (
        <FormGroup key={`${field.fieldName}-${index}`}>
          <Label for={field.fieldName}>{field.label}</Label>
          <Input
            type="select"
            id={field.fieldName}
            value={selectedValues[field.fieldName] || ""}  // Bind to selectedValues directly
            onChange={(e) =>
              handleInputChange(
                e,
                field.fieldName
              )
            }
          >
            <option value="">Select an option</option>
            {field.options.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </Input>
        </FormGroup>
      );
    }
  
    return (
      <FormGroup key={`${field.fieldName}-${index}`}>
        <Label for={field.fieldName}>{field.label}</Label>
        <Input
          type={field.type}
          id={field.fieldName}
          placeholder={field.placeholder}
          value={selectedValues[field.fieldName] || ""}  // Bind to selectedValues directly
          onChange={(e) => handleInputChange(e, field.fieldName)}
        />
      </FormGroup>
    );
  };
  

  return (
    <Container className={classes.container}>
      <div className={classes.headerSection}>
        <h2 className={classes.title}>Integration Settings</h2>
        <p>
          Manage your external integrations by enabling or disabling them. Once enabled, you can provide the required details for the integration to work seamlessly with your opportunities.
        </p>
      </div>

      <Row className="mt-4">
        {integrations.map((integration, index) => (
          <Col md="12" key={index} className="mb-3">
            <Card
              className={`${classes.card} ${enabledIntegrations[integration.code.toLowerCase()] ? classes.enabledCard : ''}`}
            >
              <CardHeader className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center w-100">
                  <div className="d-flex align-items-center">
                    <img
                      src={integration.logo}
                      alt={`${integration.name} logo`}
                      className={classes.integrationLogo}
                    />
                    <h5 className="mb-0 ms-3"> {integration.name}</h5>
                  </div>

                </div>
                
                {integration.fields.length === 0 ? (
                  <Button
                      style={{ marginRight: "10px" }}
                      color={enabledIntegrations[integration.code.toLowerCase()] ? "danger" : "primary"}
                      onClick={() => toggleIntegration(integration.code.toLowerCase(), !enabledIntegrations[integration.code.toLowerCase()])}
                    >
                      {enabledIntegrations[integration.code.toLowerCase()] ? "Disable" : "Enable"}
                    </Button>
                    
                ) : (
                      <Button
                      style={{ marginRight: "10px" }}
                      color={activeIntegration === index ? "danger" : "primary"}
                      onClick={() => setActiveIntegration(activeIntegration === index ? null : index)}
                    >
                      {activeIntegration === index ? "Close" : "View"}
                    </Button>
                )}
              </CardHeader>
              {integration.fields.length > 0 && (
                <Collapse isOpen={activeIntegration === index}>
                  <CardBody>

                    <p className={classes.integrationInfo}>{integration.info}</p>

                    <Form>
                      {integration.fields.map((field, fieldIndex) => renderField(field, fieldIndex))}

                      {/* Show message if 'Other' is selected in the ATS dropdown */}
                      {selectedValues["ATS-Select ATS"] === "Other" && (
                        <p className={classes.contactMessage}>
                          We will be in touch about your needs.
                        </p>
                      )}

                      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                      <Button
    style={{ marginRight: "10px" }}
    color="danger"
    onClick={() => disableIntegration(integration.code.toLowerCase())}
  >
    Disable
  </Button>
                        <Button color="primary" onClick={() => saveIntegration(integration.code.toLowerCase())}>
                          Save Integration
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Collapse>
              )}
            </Card>
          </Col>
        ))}
      </Row>
      <FacebookLogin ref={facebookLoginRef} />
    </Container>
  );
});

export default Integration;
