import { memo, useMemo, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CustomInput from "components/Common/CustomInput";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from 'components/Common/CustomDropdown/CustomDropdown';
import { setErrorMess, setLoading, setSuccessMess } from "redux/reducers/Status/actionTypes";
import { AssistCreate } from 'services/Contractor/NewMember/AssistCreate';
import { ReducerType } from "redux/reducers";
import classes from "./styles.module.scss";
import ImageUpload from "components/CustomUpload";
import CustomTextarea from "components/CustomTextarea";
import CustomTagsInput from "components/Common/CustomTagsInput";
import { ImageService } from "services/Image";
import { OrganizationService } from "services/Employer/NewMember/Organization";
import { getSetting, setSettingReducer } from "redux/reducers/Employer/actionTypes";
import { ContractorSettingService } from "services/Contractor/Settings/Email";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
import SearchLocationInput from "components/Common/GoogleAddress";
import { UserService } from "services/User";
import { setUserReducer, setUserAvatarReducer } from "redux/reducers/User/actionTypes";

interface Props {}
interface ImageFile extends File {}

interface SettingFormData {
  idBasicProfile?: number;
  title: string;
  workPhone?: string;
  avatar?: File;
  companyAvatar?: ImageFile;
  idOrganisation?: number;
  name?: string;
  industry?: string;
  numberOfWorkers?: number[];
  mission?: string;
  culture?: string;
  behaviouralSklls?: string[];
  email?: string;
  address?: string;
  city?: string;
  country?: string;
  colour?: string;
  EmailNotification?: boolean;
}

const Setting = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { setting } = useSelector((state: ReducerType) => state.employer);
  const [colours, setColours] = useState(setting?.colour);
  const [settingId, setSettingId] = useState();
  const [initialImage, setInitialImage] = useState(null);

  const schema = useMemo(() => {
    return yup.object().shape({
      title: yup.string().required("This field is required"),
      idOrganisation: yup.string().nullable(),
      name: yup.string().required("This field is required"),
      numberOfWorkers: yup
        .array()
        .of(yup.number())
        .typeError("That doesn't look like the number")
        .nullable(),
      behaviouralSklls: yup.array().of(yup.string()).nullable(),
      email: yup.string().email("Please enter a valid email").nullable(),
      address: yup.string(),
      country: yup.string().nullable(),
      city: yup.string().nullable(),
      colour: yup.string().nullable(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm<SettingFormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  useEffect(() => {
    const fetchImage = async () => {
      const imageKey = `${setting?.idOrganisation}-${S3_UPLOAD_TYPE_PRIFIX.COMPANYAVATAR}`;
      try {
        if (imageKey) {
          const imageUrl = await ImageService.getCompanyAvatar(imageKey);
          setInitialImage(imageUrl);
        }
      } catch (error) {
        console.error("Failed to load initial image", error);
      }
    };
    fetchImage();
  }, [setting]);

  useEffect(() => {
    if (setting && user) {
      reset({
        ...setting,
        idBasicProfile: setting.idBasicProfile,
        colour: setting.colour,
      });

      setValue("email", user.email);

      ContractorSettingService.getContractorSettingService(user?.id)
        .then((res) => {
          setSettingId(res?.id);
          setValue("EmailNotification", !res?.turnOffEmails);
        })
        .catch((err) => dispatch(setErrorMess(err)));

      if (setting.photoUrl) {
        ImageService.getImageBlob(setting.photoUrl)
          .then((res) => {
            setValue("avatar", blobToFile(res as Blob, "avatar.png"));
          })
          .catch((e) => dispatch(setErrorMess(e)));
      }
    }
  }, [dispatch, reset, setValue, setting, user]);

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob as any], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };

  const onSubmit = (data: SettingFormData) => {
    dispatch(setLoading(true));

    ContractorSettingService.putContractorSettingService(user?.id, settingId, {
      turnOffEmails: !data?.EmailNotification,
    }).catch((error) => dispatch(setErrorMess(error)));

    if (data?.companyAvatar) {
      try {
        ImageService.postCompanyAvatar(
          data?.companyAvatar,
          `${data?.idOrganisation}-${S3_UPLOAD_TYPE_PRIFIX.COMPANYAVATAR}`
        );
      } catch (error) {
        console.error("Upload error:", error);
        throw error;
      }
    }
    ImageService.postImage(
      data?.avatar,
      `${user?.id}-${S3_UPLOAD_TYPE_PRIFIX.AVATAR}`
    )
      .then(async (res) => {
        const arrSubmit = [];
        let chatSecret = null;
        let form_data = new FormData();
        form_data.append("username", user?.id.toString());
        if (data?.avatar) {
          form_data.append("avatar", data?.avatar, data?.avatar.name);
        }

        if (data?.idOrganisation) {
          arrSubmit.push(
            OrganizationService.putOrganization(
              {
                name: data?.name,
                mission: data?.mission,
                culture: data?.culture,
                industry: data?.industry,
                numberOfWorkers: data?.numberOfWorkers,
                behaviouralSklls: data?.behaviouralSklls,
                email: data?.email,
                city: data?.city,
                country: data?.country,
                colour: data?.colour, 
              },
              data?.idOrganisation
            )
          );
        } else {
          arrSubmit.push(
            OrganizationService.postOrganization({
              name: data?.name,
              mission: data?.mission,
              culture: data?.culture,
              industry: data?.industry,
              numberOfWorkers: data?.numberOfWorkers,
              behaviouralSklls: data?.behaviouralSklls,
              email: data?.email,
              city: data?.city,
              country: data?.country,
            })
          );
        }
        await Promise.all(arrSubmit)
          .then(async (res) => {
            dispatch(getSetting());
            if (!data?.idBasicProfile) {
              await UserService.putUser(user?.id, {
                ...user,
                chatUserId: res[1]?.id,
                chatSecret: chatSecret,
              })
                .then(() => {
                  dispatch(
                    setUserReducer({
                      ...user,
                      chatUserId: res[1]?.id,
                      chatSecret: chatSecret,
                    })
                  );
                })
                .catch((e) => dispatch(setErrorMess(e)));
            }
            dispatch(setSuccessMess("Successfully updated"));
          })
          .catch((e) => dispatch(setErrorMess(e)));
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  const allColours = ["Orange", "Red", "Green", "Purple", "Blue", "Black"];

  const handleColourChange = (selectedColour: string) => {
    setColours(selectedColour);
    dispatch(setSettingReducer({ ...setting, colour: selectedColour }));
  };

  return (
    <>
      <div>
        <div className={classes.section}>
          <Container>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.content}>
                <Controller
                  name="companyAvatar"
                  control={control}
                  render={({ field }) => (
                    <ImageUpload
                      avatar
                      value={initialImage || field.value}
                      onChange={(file) => {
                        field.onChange(file);
                      }}
                      isDisabled={!setting?.admin}
                    />
                  )}
                />
                <div className={classes.commonContainer}>
                  <Row >
                    <Col xs="12" className="d-none">
                      <CustomInput
                        placeholder="ID Organisation"
                        type="text"
                        autoComplete="off"
                        inputRef={register("idOrganisation")}
                        errorMessage={errors.idOrganisation?.message}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>Name</p>
                      <CustomInput
                        placeholder="Organisation name"
                        type="text"
                        autoComplete="off"
                        inputRef={register("name")}
                        errorMessage={errors.name?.message}
                        isDisabled={!setting?.admin}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>Industry</p>
                      <CustomInput
                        placeholder="Industry"
                        type="text"
                        autoComplete="off"
                        inputRef={register("industry")}
                        errorMessage={errors.industry?.message}
                        isDisabled={!setting?.admin}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>About Your Company</p>
                      <CustomTextarea
                        className={classes.textarea}
                        placeholder="What is your mission statement?"
                        autoComplete="off"
                        name="mission"
                        inputRef={register("mission")}
                        errorMessage={errors.mission?.message}
                        tooltipText='This section will appear on job listings if populated.'
                        isDisabled={!setting?.admin}
                      />
                    </Col>
                    <Col md="6" xs="12" className={classes.commonItem}>
                      <p className={classes.commonSubtitle}>Benefits & Culture</p>
                      <CustomTextarea
                        className={classes.textarea}
                        placeholder="What culture do you look for?"
                        name="culture"
                        autoComplete="off"
                        inputRef={register("culture")}
                        errorMessage={errors.culture?.message}
                        tooltipText='This section will appear below about your company on job listings, if populated.'
                        isDisabled={!setting?.admin}
                      />
                    </Col>
                    {setting?.admin && (
                      <>
                        <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>Number of workers</p>
                          <Controller
                            name="numberOfWorkers"
                            control={control}
                            render={({ field }) => (
                              <CustomTagsInput
                                {...field}
                                name="numberOfWorkers"
                                placeholder="Number of workers"
                                onChange={(value: any) => {
                                  return field.onChange(value);
                                }}
                                value={field.value ? field.value : []}
                                control={control}
                                errorMessage={errors.numberOfWorkers?.message}
                              />
                            )}
                          />
                        </Col>

                        <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>Behavioral skills</p>
                          <Controller
                            name="behaviouralSklls"
                            control={control}
                            render={({ field }) => (
                              <CustomTagsInput
                                {...field}
                                placeholder="Behavioral skills"
                                onChange={(value: any) => {
                                  return field.onChange(value);
                                }}
                                value={field.value ? field.value : []}
                                control={control}
                                errorMessage={errors.behaviouralSklls?.message}
                              />
                            )}
                          />
                        </Col>
                        <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>Nearest Town or City</p>
                          <Controller
                            name="address"
                            control={control}
                            render={({ field }) => (
                              <SearchLocationInput
                                {...field}
                                ref={null}
                                placeholder="Your nearest town or city"
                                errorMessage={errors.address?.message}
                                value={field.value}
                                onChange={(value: any) => {
                                  setValue("city", value?.address?.city);
                                  setValue("country", value?.address?.country);
                                  return field.onChange(
                                    value?.address?.formattedAddress
                                  );
                                }}
                              />
                            )}
                          />
                        </Col>
                        <Col md="6" xs="12" className={classes.commonItem}>
                          <p className={classes.commonSubtitle}>ATS Colour</p>
                          <CustomDropdown
                            title='Choose Brand Colour'
                            options={allColours}
                            selectedOption={colours}
                            onChange={handleColourChange}
                          />
                        </Col> 
                      </>
                    )}
                  </Row>
                </div>
                <div className={classes.btnSaveContainer}>
                  <Button type="submit" className={`btn-round ${classes.btnSave}`} color="primary">
                    <i className="fa fa-save" /> Save
                  </Button> 
                </div>
              </div>
            </Form>
          </Container>
        </div>
      </div>
    </>
  );
});

export default Setting;
