import { memo, useEffect, useState } from 'react';
import ScrollNavbar from 'components/Navbars/Contractor/ScrollNavbar';
import FooterDefault from 'components/Footers/FooterDefault';
import ProfilePageHeader from './components/ProfilePageHeader';
import MyExperience from './components/MyExperience';
import Education from './components/Education';
import FooterMobile from 'components/Footers/FooterMobile';
import WelcomeModal from './components/WelcomeModal';
import { useSelector, useDispatch } from 'react-redux';
import { ReducerType } from 'redux/reducers';
import { setWelcome } from 'redux/reducers/Status/actionTypes';

interface Props {}

// eslint-disable-next-line no-empty-pattern

const Profile = memo(({}: Props) => {
  const dispatch = useDispatch();
  
  const { isWelcome } = useSelector((state: ReducerType) => state?.status);
  //testing assist user access

  const [openWelcomeModal, setOpenWelcomeModal] = useState(false);

  useEffect(() => {
    if (isWelcome) {
      setOpenWelcomeModal(isWelcome);
      dispatch(setWelcome(false));
    }
  }, [dispatch, isWelcome]);

  useEffect(() => {
    document.body.classList.add('profile-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return () => {
      document.body.classList.remove('profile-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  const handleToggle = () => setOpenWelcomeModal(!openWelcomeModal);

  return (
    <>
      <ScrollNavbar />
      <WelcomeModal isOpen={openWelcomeModal} onClose={handleToggle} />
      <div className='wrapper'>
        <ProfilePageHeader />
        <Education />
        <MyExperience />
        <FooterDefault />
        <FooterMobile />
      </div>
    </>
  );
});

export default Profile;
