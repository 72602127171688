import { API, Storage } from 'aws-amplify';

export class JobService {
  static async postJob(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/job';
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }


  static async postIntent(data) {
    const apiName = process.env.REACT_APP_API_NAME; 
    const path = '/create-payment-intent'; 
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        console.log('Response from API:', res);
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        console.error('Error from API:', e);
        return Promise.reject(e?.response?.data);
      });
  }
  

  static async postJobDescription(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/job/templates/jobdescriptions';
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async postJobTemplates(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/job/templates';
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async postProject(data) {
    // console.log("start API")
    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/projects';
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        // console.log("THIS IS RESULT", res)
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        // console.log("THIS IS error", e?.response?.data)
        return Promise.reject(e?.response?.data);
      });
  }

  static async putJobAssist(id, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/assist/${id}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  static async postSocial(body) { 
    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/job/social';
    const myInit = {
      response: true,
      body, // Use the entire body object passed as a parameter
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putHeadhunter(id, data) {
    
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/headhunter/${id}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }


  static async putHeadhunter2(jobId, profileIds, region) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/headhunter/headhunter2`; 
    const myInit = {
      response: true,
      body: {
        job_id: jobId,
        profile_ids: profileIds,
        region: region,
      },
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  
  static async checkHeadHunterStatus(id) {
    // console.log("id",id)
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/headhunter/check/${id}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then(res => {
        return Promise.resolve(res.data);
      })
      .catch(e => {
        return Promise.reject(e?.response?.data);
      });
  }
  

  static async getJob() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/job';
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        // console.log("get job results", res)
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getJobTemplates() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/job/templates';
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        // console.log("get job results", res)
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }


  static async getJobClient() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/job/clientjob';
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        // console.log("get job results", res)
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }




  static async getClient() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/job/assist/clients';
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  
  static async putClient(data, id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/assist/client/${id}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        // console.log("res",res)
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        // console.log("e",e)
        return Promise.reject(e?.response?.data);
      });
  }

  static async postClient(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/assist/client`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        console.log("res",res)
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        console.log("e",e)
        return Promise.reject(e?.response?.data);
      });
  }

  static async postTriggerProposal(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/assist/client/proposal`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        console.log("res",res)
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        console.log("e",e)
        return Promise.reject(e?.response?.data);
      });
  }


  static async getJobAll() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/job/all';
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getJobOrg() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = '/job/jobOrg';
    const myInit = {
      queryStringParameters: {
        //userId: '523' // Sending the hardcoded userId as a query parameter
      },
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
}


  static async putJob(data, id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${id}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putJobTemplates(data, id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/templates/${id}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async archiveJob(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${id}/archive`;
    const myInit = {
      response: true,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async archiveJobTemplate(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/templates/${id}/archive`;
    const myInit = {
      response: true,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
  

  static async getContractorCardForJob(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${id}/contractorcards`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getAllOffersForOneJob(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${id}/connectoffers`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        // console.log(res.data);
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getContractorCardsOffered(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${id}/contractorcards/offered`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async postJobOffer(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/connectoffer`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putJobOffer(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/connectoffer`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putJobOfferHeadHunter(data) {
    // console.log("triggered", data)
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/connectoffer/connectofferhunt`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        // console.log(res)
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  

  static async postDeclineCandidate(jobId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/connectoffers/declined`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putDeclineCandidate(jobId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/connectoffers/declined`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getDeclinedCandidateList(jobId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/connectoffers/declined`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getContractorCandidates(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${id}/contractorcards?contractorIds=81,82`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getAllOffers() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/connectoffers`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getOfferForJob(jobId, offerId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/connectoffers/${offerId}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getContractorProfileJobOffer(jobId, contractorId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/contractors/${contractorId}/profile`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getContractorPreferenceJobOffer(jobId, contractorId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/contractors/${contractorId}/preference`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getContractorExperiencesJobOffer(jobId, contractorId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/contractors/${contractorId}/experiences`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getContractorEducationsJobOffer(jobId, contractorId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/contractors/${contractorId}/educations`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getContractorContactJobOffer(jobId, contractorId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/contractors/${contractorId}/contact`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getContractorTestimonialsJobOffer(jobId, contractorId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/contractors/${contractorId}/testimonials`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getContractorVerificationJobOffer(jobId, contractorId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/contractors/${contractorId}/verification`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getContractorInformation(contractorId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${contractorId}/card`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async syncAIJob(jobId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/aisync`;
    const myInit = {
      response: true,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getHeadHunter(data, id) {
    // console.log("test");
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/headhunter/${id}/get`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  // this is where we can turn on ec2 and run and then turn off ec2 
  static async headHunter(data) {
    // console.log("testing headhunter post", data);
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/headhunter`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        // Log the complete error object
        console.error("Error in headHunter service:", e);
        // Reject with a more descriptive error or the received error
        return Promise.reject(e?.response?.data || "Error occurred in headHunter service");
      });
  }

  static async headHuntertrigger(data) {
    // console.log("testing headhunter post", data);
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/headhunter/trigger`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        // Log the complete error object
        console.error("Error in headHunter service:", e);
        // Reject with a more descriptive error or the received error
        return Promise.reject(e?.response?.data || "Error occurred in headHunter service");
      });
  }


  

  static async headHunterUpdate(data, id) {
    // console.log("test", data);
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/headhunter/${id}/delete`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        // Log the complete error object
        console.error("Error in headHunter service:", e);
        // Reject with a more descriptive error or the received error
        return Promise.reject(e?.response?.data || "Error occurred in headHunter service");
      });
  }

  static async nectaAlgo(data) {
    // console.log("test");
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/rankings`;
    const myInit = {
      response: true,
      body: data,
    };
    // console.log("nectaAlgo called");
    // console.log(myInit)
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }


  static async getAIMatch(jobId) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/aimatch `;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getSomeContractorCard(jobId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${jobId}/contractorcards?contractorIds=${data}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  // Interview offer

  static async postInterview(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/interviewoffer`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putInterview(data, id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/interviewoffer/${id}/offer`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getAllInterviewOfferOfAJob(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${id}/interviewoffers`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getAllOffersOther() {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/connectoffers/allother`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  // Contract offer

  static async postContract(file, key) {
    try {
      if (file) {
        const response = await Storage.put(key, file, {
          level: 'public',
          contentType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        return response?.key;
      }
      return '';
    } catch (error) {
      console.log(error);
    }
  }

  static async getContract(key, identityId) {
    if (identityId) {
      try {
        const response = await Storage.get(key, {
          download: true,
          level: 'public',
          identityId: identityId,
        });
        return response?.Body;
      } catch (error) {
        console.log(error, 'fail');
      }
    } else {
      try {
        const response = await Storage.get(key, {
          download: true,
          level: 'public',
        });
        return response?.Body;
      } catch (error) {
        console.log(error, 'fail');
      }
    }
  }

  static async getTemplate(key) {
    try {
      const response = await Storage.get(key, {
        download: true,
        level: 'public',
      });
      return response?.Body;
    } catch (error) {
      console.log(error, 'fail');
    }
  }

  static async postContractJobOffer(data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/joboffer`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putContractJobOffer(data, id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/joboffer/${id}/offer`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async revokeContractJobOffer(data, id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/joboffer/${id}/revoke`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getAllSentContractOfAJob(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/job/${id}/joboffers`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async checkAccountExistOnNecta(id, email) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/user/${id}/verifyuser?email=${email}`;
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async inviteToBeApproverMail(offerId, data) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/offers/${offerId}/invitetimesheetapprover`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
