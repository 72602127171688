import { memo, useEffect, useMemo } from "react";
import { Form, Button, Row, CardHeader, Card } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import CustomInput from "components/Common/CustomInput";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { OrganizationService } from "services/Employer/NewMember/Organization";
import CustomTextarea from "components/CustomTextarea";
import { ReducerType } from "redux/reducers";
import { setSettingReducer } from "redux/reducers/Employer/actionTypes";
import SearchLocationInput from "components/Common/GoogleAddress";

interface Props {}

interface OrganisationFormData {
  recruitOnBehalf: boolean;
  id?: number;
  name?: string;
  mission?: string;
  culture?: string;
  city?: string;
  country?: string;
  address?: string;
  email?: string;
}

// eslint-disable-next-line no-empty-pattern
const Organisation = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { setting } = useSelector((state: ReducerType) => state.employer);

  const schema = useMemo(() => {
    return yup.object().shape({
      recruitOnBehalf: yup.boolean().oneOf([true, false]),
      id: yup.number(),
      name: yup.string().required("This field is required"),
      mission: yup
        .string()
        .nullable()
        .when("recruitOnBehalf", {
          is: false,
          then: yup.string().required("This field is required"),
        }),
      culture: yup
        .string()
        .nullable()
        .when("recruitOnBehalf", {
          is: false,
          then: yup.string().required("This field is required"),
        }),
      address: yup.string(),
      country: yup.string(),
      city: yup.string(),
      email: yup.string().email(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<OrganisationFormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const recruitOnBehalf = watch("recruitOnBehalf");

  useEffect(() => {
    if (setting && user) {
      reset({
        recruitOnBehalf: setting?.recruitOnBehalf,
        id: setting.idOrganisation,
        name: setting.name,
        mission: setting.mission,
        culture: setting.culture,
        address:
          setting.city && setting.country
            ? `${setting.city}, ${setting.country}`
            : "",
        email: user?.email,
      });
    }
  }, [dispatch, reset, setting, user]);

  const handleBack = () => {
    dispatch(push(routes.employer.newMember.basicProfile));
  };

  const onSubmit = async (data: OrganisationFormData) => {
    const dataSubmit = {
      name: data.name,
      mission: data.mission,
      culture: data.culture,
      city: data.city,
      country: data.country,
      email: data.email,
    };
    dispatch(setLoading(true));
    if (data?.id) {
      console.log("Triggered PUT CALL")
      await OrganizationService.putOrganization(dataSubmit, data?.id)
        .then((res) => {
          dispatch(
            setSettingReducer({
              ...setting,
              idOrganisation: res?.id,
              name: res?.name,
              mission: res?.mission,
              culture: res?.culture,
              city: res?.city,
              country: res?.country,
              email: res?.email,
            })
          );
          dispatch(push(routes.employer.admin.dashboard));
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    } else {
      console.log("Triggered POST CALL")
      await OrganizationService.postOrganization(dataSubmit)
        .then((res) => {
          dispatch(
            setSettingReducer({
              ...setting,
              idOrganisation: res?.id,
              name: res?.name,
              mission: res?.mission,
              culture: res?.culture,
              city: res?.city,
              country: res?.country,
              email: res?.email,
            })
          );
          dispatch(push(routes.employer.admin.dashboard));
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    }
  };

  return (
    <Card className={classes.card}>
      <CardHeader>
        <p className={classes.title}>Your Organisation</p>
      </CardHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.content}>
          <CustomInput
            placeholder="Organisation name"
            type="text"
            autoComplete="off"
            inputRef={register("name")}
            errorMessage={errors.name?.message}
          />

          {!recruitOnBehalf && (
            <>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <SearchLocationInput
                    {...field}
                    ref={null}
                    placeholder="Your nearest town or city"
                    errorMessage={errors.address?.message}
                    value={field.value}
                    onChange={(value: any) => {
                      setValue("city", value?.address?.city);
                      setValue("country", value?.address?.country);
                      return field.onChange(value?.address?.formattedAddress);
                    }}
                  />
                )}
              />

              <CustomTextarea
                className={classes.textarea}
                placeholder="What is your mission statement?"
                autoComplete="off"
                inputRef={register("mission")}
                errorMessage={errors.mission?.message}
              />

              <CustomTextarea
                className={classes.textarea}
                placeholder="What culture do you look for?"
                autoComplete="off"
                inputRef={register("culture")}
                errorMessage={errors.culture?.message}
              />
            </>
          )}
        </div>
        <Row className="justify-content-between p-0 m-0">
          <Button
            size="md"
            color="default"
            type="button"
            className={classes.btn}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            size="md"
            color="primary"
            type="submit"
            className={classes.btn}
          >
            Next
          </Button>
        </Row>
      </Form>
    </Card>
  );
});

export default Organisation;
