import { API } from "aws-amplify";

export class SovrenService {
  // call when user updates contractor preferences
  static async putAiCvTags(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/aicvtags`;
    const myInit = {
      response: true,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async postAiSync(id) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractors/${id}/aisync`;
    const myInit = {
      response: true,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}
